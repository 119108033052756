import React, { useEffect, useState, useContext, useRef } from "react";
import '../AdminDashboard/AdminDashboard.css'
import { useNavigate } from "react-router-dom";
import AdminHeader from "../AdminHeader";
import { URLS, usePageTracking } from "../../../utilities/constants";
import axios from "axios";
import { DataContext } from '../../../utilities/DataContext';
import AlertComponent from "../../../Components/AlertComponent";
import { debounce } from "lodash";
import ReactGA from 'react-ga4';
import { useAnalytics } from "../../../utilities/AnalyticsContext";

const AdminDashBoard = () => {
    const { markTitleUpdated } = useAnalytics();
    const { setPageTitleAvailable, setBusinessForm1data, setBusinessForm2data, token, setIsLoggedIn, setToken, setUserData, setCompanyInfo, setUserType, userData, setChatData, setMessageCount, setTab, setNoOfBedErr, setAddDetails } = useContext(DataContext);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [instaList, setInstaList] = useState([])
    const [responseErr, setResponseErr] = useState('')
    const previousTitleRef = useRef();
    const pageTitle = 'Admin Dashboard'

    const gotoInstaList = () => {
        navigate('/admin/instaposts')
    }

    const gotoLogin = () => {
        setResponseErr('')
        setUserData({})
        setCompanyInfo({})
        setUserType('')
        setTab('')
        setNoOfBedErr('')
        setMessageCount('')
        setToken('')
        setChatData([])
        setBusinessForm1data({})
        setBusinessForm2data({})
        setIsLoggedIn(false)
        setAddDetails({})
    }


    useEffect(() => {
        document.title = 'Admin Dashboard'
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = ((title) => {

        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    });

    // const debouncedTrackPageTitleChange = debounce((title) => {
    //     if (previousTitleRef.current !== title && window.trackPageTitleChange) {
    //         window.trackPageTitleChange(title);
    //     }
    //     // Update the previous title reference
    //     previousTitleRef.current = title;
    // }, 500);



    useEffect(() => {
        getInstaPosts()
    }, [])

    const getInstaPosts = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.INSTA_POSTS}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log('response', response)
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response?.data.data
                    setInstaList(data)
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response && error.response.data.message === 'Ungültiges Token. Bitte melden Sie sich erneut an.') {
                    setResponseErr(error.response.data.message);
                }
            });
    }

    return (
        <>
            <AdminHeader></AdminHeader>
            <div style={{ padding: '5vmin 0 0 3vmin', position: 'absolute', top: 80 }}>
                <span className="adminheadertext">Administraton Dashboard</span>
                <div className="div_grid" onClick={gotoInstaList}>
                    <span className="instatext">{instaList.length}</span>
                    <span className="instatextheader">Insta Posts</span>
                </div>
                {responseErr !== '' && <AlertComponent message={responseErr} onClose={() => { gotoLogin() }} />}
            </div>
        </>

    )
}
export default AdminDashBoard;

