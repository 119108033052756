import React, { useState, createRef, useRef, useContext, useEffect } from 'react';
import { Collapse, Card, Row, Col, Popover, OverlayTrigger, Button, Modal } from 'react-bootstrap';
import '../../app.css'
import '../Ads/Ads.css';
import { newtaburl, Strings, URLS } from '../../utilities/constants';
import axios from 'axios';
import { DataContext } from '../../utilities/DataContext';
import ReactQuill from 'react-quill'
import { ClipLoader } from 'react-spinners';
import TopLabelInput from '../../Components/TpLabelInput/TopLabelInput';
import ImageTextEditor from '../../Components/ImageTextEditor/ImageTextEditor';
import 'quill/dist/quill.snow.css'
import 'react-image-crop/dist/ReactCrop.css';
import { useNavigate } from 'react-router-dom';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import AlertComponent from "../../Components/AlertComponent";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] }
    ],
    [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
  ]
};

var formats = [
  "header", "height", "bold", "italic",
  "underline", "strike", "blockquote",
  "list", "color", "bullet", "indent",
  "link", "image", "align", "size",
];

const AdGallery = ({ onBackPress, onNextPress, tabchanged, addId, onClick }) => {
  const navigate = useNavigate();
  const cropperRef = useRef(null);
  const { userData, token, addDetails, setAddDetails, tab } = useContext(DataContext);
  const [popoverOpen, setPopoverOpen] = useState({ sectionIndex: null, imageIndex: null });
  const [showDeletePopover, setShowDeletePopover] = useState({ sectionIndex: null, imageIndex: null });
  const [showModal, setShowModal] = useState(false);
  const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100 });
  const [imageRef, setImageRef] = useState(null);
  const [imageUrl, setImgUrl] = useState(null)
  const [sectionindex, setSectionIndex] = useState(0)
  const [imageIndex, setImageIndex] = useState(0)
  const [cropedImage, setCropedImage] = useState(null)
  const [galleryTabOpened, setGalleryTabOpend] = useState(false)
  const [showImageTextEditor, setShowImageTextEditor] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const [showalert, setShowAlert] = useState(false)
  const [imageErros, setImageErrors] = useState([])


  const [sections, setSections] = useState([
    { id: 1, title: 'Außen', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 2, title: 'Wohnen', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 3, title: 'Küche', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 4, title: 'Bad', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 5, title: 'Schlafen', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 6, title: 'Cockpit', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 7, title: 'Dach', vehicle_category_images: [], isOpen: false, header: '', description: '' },
    { id: 8, title: 'Keller', vehicle_category_images: [], isOpen: false, header: '', description: '' },

    // Add more sections as needed
  ]);

  const inputRefs = useRef(Array(sections.length).fill().map(() => createRef()));
  const sectionsDataRef = useRef(sections);

  useEffect(() => {
    setGalleryTabOpend(true)
  }, [galleryTabOpened]);


  useEffect(() => {
    if (galleryTabOpened !== true) {
      if (addId !== undefined && addId !== '') {
        callAddDetailApi(addId, '')
      } else if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
        callAddDetailApi(addDetails.vehicle_ad_overview_data.id, '')
      }
    }


    return () => {
      console.log('tabchangedgallery', tab)
      if (tab !== 'gallery' && tab !== '') {
        setGalleryTabOpend(false)
        handleNextClick('tabchange')
      }
    };
  }, [tab]);


  useEffect(() => {
    sectionsDataRef.current = sections;
  }, [sections]);

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleMouseEnter = (sectionIndex, imageIndex) => {
    setPopoverOpen({ sectionIndex, imageIndex });
  };

  const handleMouseLeave = () => {
    setPopoverOpen({ sectionIndex: null, imageIndex: null });
  };

  const handleDeleteClick = (sectionIndex, imageIndex) => {
    setPopoverOpen({ sectionIndex: null, imageIndex: null });
    console.log('sectionIndex', sectionIndex, 'imageIndex', imageIndex)
    setShowDeletePopover({ sectionIndex, imageIndex });
  };

  const handleEditClick = (sectionIndex, imgIndex) => {
    const section = sections[sectionIndex];
    const image = section.vehicle_category_images[imgIndex]
    setImgUrl((image.original_image instanceof File || image.original_image instanceof Blob) ? URL.createObjectURL(image.original_image) : image.original_image)
    setSectionIndex(sectionIndex)
    setImageIndex(imgIndex)
    setPopoverOpen({ sectionIndex: null, imageIndex: null });
    setShowModal(true);
  };



  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    const croppedCanvas = cropper.getCroppedCanvas();
    console.log('blob', croppedCanvas); // This is the cropped canvas

    // Convert canvas to blob
    croppedCanvas.toBlob(blob => {
      // Log the blob object
      console.log('blob', blob);
      // Generate a URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      setCropedImage(blob)
      // Log the blob URL
      console.log('blob URL', blobUrl);
    });
  };


  function generateRandomFileName() {
    const randomNumber = Math.floor(10 + Math.random() * 90); // Generates a random number between 10 and 99
    return `image_${randomNumber}.png`;
  }

  function createFileFromBlob(blob) {
    console.log('blob', blob)
    const fileName = generateRandomFileName();
    return new File([blob], fileName, { type: blob.type });
  }

  // const handleImageLoaded = (e) => {
  //   const img = e.target;
  //   setImageRef(img);
  // };

  const handleCropChange = (newCrop) => {
    setCrop(newCrop);
    if (imageRef && newCrop.width && newCrop.height) {
      // Get the cropped area from the original image
      const canvas = document.createElement('canvas');
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
      canvas.toBlob((blob) => {
        if (blob) {
          try {
            setCropedImage(blob)
          } catch (error) {
            console.error('Error creating object URL:', error);
          }
        } else {
          console.error('Failed to generate blob from canvas');
        }
      }, 'image/png');
    }
  };

  const saveImgCrop = () => {
    callAddGalleryUpdateApi(sections[sectionindex].title, cropedImage)
    // setSections(prevSections => {
    //   const updatedSections = [...prevSections];
    //   const sectionToUpdate = { ...updatedSections[sectionindex] };
    //   const updatedImages = [...sectionToUpdate.vehicle_category_images];

    //   // Update the image at the specified index
    //   if (imageIndex >= 0 && imageIndex < updatedImages.length) {
    //     updatedImages[imageIndex]['updated_image'] = cropedImage;
    //   } else {
    //     // Handle error if the image index is invalid
    //     console.error('Invalid image index');
    //   }

    //   sectionToUpdate.vehicle_category_images = updatedImages;
    //   updatedSections[sectionindex] = sectionToUpdate;
    //   return updatedSections;
    // });
    setShowModal(false); // Close the modal after saving changes
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseDeletePopover = (sectionIndex, imageIndex) => {
    setShowDeletePopover({ sectionIndex: null, imageIndex: null });
    setPopoverOpen({ sectionIndex, imageIndex });
  }

  const handleConfirmDelete = (sectionIndex, imageIndex) => {
    setShowDeletePopover({ sectionIndex: null, imageIndex: null });
    setPopoverOpen({ sectionIndex, imageIndex });
    const section = sections[sectionIndex];
    const updatedImages = [...section.vehicle_category_images];
    callAddGalleryDeleteApi(updatedImages[imageIndex])
  }

  //oldcode for signle call
  // const handleImageUpload = (event, index) => {
  //   // for multiple images
  //   const files = event.target.files;
  //   callAdGalleryCreateApi(sections[index].title, files);
  // };

  //newcode for muliple calls 
  const handleImageUpload = (event, index) => {
    const files = event.target.files;
    const imageArray = Array.from(files);
    let errorsList = []; // Array to collect errors
    let validImages = []; // Array to collect valid images

    imageArray.forEach((file) => {
      if (file.size > 15 * 1024 * 1024) { // Check if file size is greater than 4.5MB
        errorsList.push(`${file.name}: ${Strings.image_size_big_error}`);
      } else if (file.name.length > 100) { // Check if filename length is greater than 100 characters
        errorsList.push(`${file.name}: ${Strings.image_filename_error}`);
      } else {
        validImages.push(file); // Add to valid images if no errors
      }
    });

    // Show all error messages in a single popup if there are errors
    if (errorsList.length > 0) {
      // alert(`The following images have issues:\n\n${errorsList.join('\n')}`);
      setImageErrors((prevErrors) => ({
        ...prevErrors,
        [index]: errorsList, // Set or update errors for the specific section
      }));

    } else {
      setImageErrors([]); // Clear errors if no issues
    }

    // Proceed with valid images
    if (validImages.length > 0) {
      addImageToSection(index, validImages);
    }
  }

  const addImageToSection = (index, imageFiles) => {
    console.log('imageFiles', imageFiles);
    if (!imageFiles || imageFiles.length === 0) {
      return;
    }

    const updatedSections = [...sections]; // Create a copy of the sections array

    // Iterate over imageFiles and add each to the section
    imageFiles.forEach(imageFile => {
      const imageObj = { 'original_image': imageFile, 'updated_image': imageFile, id: '', isLoaded: false, vehicle_category_order_id: '' };
      updatedSections[index].vehicle_category_images.push(imageObj);
    });

    setSections(updatedSections);

    // Call the API with the list of image files
    const success = callAdGalleryCreateApi(updatedSections[index].title, imageFiles);
    console.log('updatedSections', updatedSections);

    return success;
  };

  const toggleSection = (sectionIndex) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      newSections[sectionIndex].isOpen = !newSections[sectionIndex].isOpen;
      return newSections;
    });
  };


  const handleImageLoaded = async (src, sectionIndex, imageIndex) => {
    const imageObj = sections[sectionIndex].vehicle_category_images[imageIndex];
    // Check if the image has already been loaded
    if (imageObj.isLoaded) {
      return;
    }
    try {
      let blob;
      if (src instanceof File || src instanceof Blob) {
        blob = src;
      } else {
        const response = await fetch(src);
        blob = await response.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imgElement = new Image();
          imgElement.src = reader.result;

          imgElement.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set maximum width and height (adjust as needed)
            const maxWidth = 1920; // You can adjust this value
            const maxHeight = 1080; // You can adjust this value

            // Calculate new dimensions to fit within maxWidth and maxHeight while preserving aspect ratio
            let newWidth = imgElement.width;
            let newHeight = imgElement.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const aspectRatio = newWidth / newHeight;

              if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              }

              if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with new dimensions
            ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

            canvas.toBlob((blob) => {
              const file = new File([blob], 'image.jpg', {
                type: 'image/jpeg', // Change the type according to your original image
                lastModified: Date.now()
              });


              console.log(`${imageIndex}Converted business image file size:`, file.size);
              console.log("Converted business image file: ", file);
              const updatedSections = [...sections];
              updatedSections[sectionIndex].vehicle_category_images[imageIndex].updated_image = blob;
              updatedSections[sectionIndex].vehicle_category_images[imageIndex].isLoaded = true;
              setSections(updatedSections);
              console.log("updatedSections: ", updatedSections);
            }, 'image/jpeg', 0.9); // Adjust JPEG quality as needed (0.7 is just an example)
          };
        }
      }


    } catch (error) {
      console.error('Error loading image as Blob:', error);
    }

  };

  const handleBackClick = () => {
    if (addDetails && !isEmptyObject(addDetails)) {
      handleNextClick('back')
    } else {
      onBackPress()
    }

  }

  const handleNextClick = (btnAction) => {
    if (!isEmptyObject(addDetails) && !isEmptyObject(addDetails.vehicle_ad_gallery_category_descriptions_data)) {
      console.log('emptyobject', addDetails.vehicle_ad_gallery_category_descriptions_data)
      callAdGalleryDataUpdateApi(btnAction)
    } else {
      if (addDetails && !isEmptyObject(addDetails)) {
        callAdGalleryDataCreateApi(btnAction)
      }
    }
  }



  const handleInputChange = (value, sectionIndex) => {
    setSections(prevSections => {
      const updatedSections = prevSections.map((section, index) =>
        index === sectionIndex ? { ...section, header: value, description: section.description === '<p><br></p>' ? '' : section.description } : section
      );
      console.log('updatedSections', updatedSections)
      return updatedSections;
    });
  };


  const handleDescriptionChange = (content, sectionIndex) => {
    console.log('content', content)
    // setDescription(content);
    setSections(prevSections => {
      const updatedSections = prevSections.map((section, index) =>
        index === sectionIndex ? { ...section, description: content === '<p><br></p>' ? '' : content } : section
      );
      console.log('updatedSections', updatedSections)
      return updatedSections;
    });
  }


  const formatAddName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };

  const openNewTab = () => {
    // Check if running in development or production
    const formattedAddName = formatAddName(addDetails.vehicle_ad_overview_data.vehicle_name);
    // const baseUrl =
    //   process.env.NODE_ENV === "development"
    //     ? URLS.APP_LOCAL_URL
    //     : URLS.APP_STAGIN_URL;
    // const formattedCompanyName = formatCompanyName(userData.company_name);
    const baseUrl = newtaburl
    const componentPage = `ad/${addDetails.vehicle_ad_overview_data.id}`;
    const url = baseUrl + componentPage;
    window.open(url, "_blank");
  };

  const handleOnDragEnd = async (result, sectionIndex) => {
    if (!result.destination) return;

    const { source, destination } = result;
    let sourceImage, destinationImage;
    let category
    let section
    setSections((prevSections) => {
      const updatedSections = [...prevSections];
      section = updatedSections[sectionIndex];
      if (section.vehicle_category_images.length <= 1) {
        console.log('Only one image in the section, skipping API call');
        return updatedSections; // Return unchanged sections
      } else {
        const reorderedImages = Array.from(section.vehicle_category_images);

        // Get source and destination images
        sourceImage = reorderedImages[source.index];
        destinationImage = reorderedImages[destination.index];
        if (sourceImage.id !== destinationImage.id) {
          // Move dragged item within the array
          const [movedItem] = reorderedImages.splice(source.index, 1);
          reorderedImages.splice(destination.index, 0, movedItem);
          category = section.title

          section.vehicle_category_images = reorderedImages;

        }
        return updatedSections;
      }
    });


    if ((section.vehicle_category_images.length > 1) && (sourceImage.id !== destinationImage.id)) {
      const payload = [
        { image_id: sourceImage.id, order_id: destinationImage.vehicle_category_order_id, vehicle_feature_category: category },
        { image_id: destinationImage.id, order_id: sourceImage.vehicle_category_order_id, vehicle_feature_category: category },
      ];
      console.log('payload', payload)
      console.log('sourceImage', sourceImage)
      console.log('destinationImage', destinationImage)
      // Prepare payload
      const url = `${URLS.BASE_URL}${URLS.AD_GALLERY_ORDER_UPDATE}${addDetails.vehicle_ad_overview_data.id}/`;
      console.log('url', url)
      // Send updated source and destination orders to the API
      try {
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json"
        const response = await axios.put(url, payload, { headers: { 'Authorization': token } })
        if (response.status === 200) {
          console.log('Order updated successfully:', response.data);
        } else {
          console.error('Failed to update order:', response);
        }
      } catch (error) {
        console.error('Error updating order:', error);
        // Optionally revert changes in case of an API failure
        setSections((prevSections) => {
          const revertedSections = [...prevSections];
          revertedSections[sectionIndex].vehicle_category_images = [...sections[sectionIndex].vehicle_category_images];
          return revertedSections;
        });
      }
    }
  };




  const callAdGalleryDataUpdateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_DATA_UPDATE}${addDetails.vehicle_ad_overview_data.id}/`;
    const vehicle_ad_overview = addDetails.vehicle_ad_overview_data.id;
    const result = sectionsDataRef.current.map(item => ({
      vehicle_feature_category: item.title,
      category_heading: item.header,
      category_description: item.description
    }));
    console.log('resultarray', result)
    const form = new FormData();
    form.append('vehicle_ad_overview', vehicle_ad_overview)
    form.append('category_descriptions_data', JSON.stringify(result))
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.put(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, btnAction)
          // if (btnAction == 'next') {
          //   onNextPress()
          // } else if (btnAction == 'preview') {
          //   openNewTab()
          // } else if (btnAction == 'back') {
          //   onBackPress()
          // } else if (btnAction == 'tabchange') {
          //   onClick()
          // }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });

  }

  const callAdGalleryDataCreateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_DATA_CREATE}`;

    const vehicle_ad_overview = addDetails.vehicle_ad_overview_data.id;
    const result = sectionsDataRef.current.map(item => ({
      vehicle_feature_category: item.title,
      category_heading: item.header,
      category_description: item.description
    }));
    console.log('resultarray', result)
    const form = new FormData();
    form.append('vehicle_ad_overview', vehicle_ad_overview)
    form.append('category_descriptions_data', JSON.stringify(result))
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.post(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, btnAction)
          // if (btnAction == 'next') {
          //   onNextPress()
          // } else if (btnAction == 'preview') {
          //   openNewTab()
          // } else if (btnAction == 'back') {
          //   onBackPress()
          // } else if (btnAction == 'tabchange') {
          //   onClick()
          // }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const callAddGalleryUpdateApi = async (category, updatedImage) => {
    setLoading(true)
    const imageId = sections[sectionindex].vehicle_category_images[imageIndex].id
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_UPDATE}${imageId}/`;
    let croppedImage = null
    if (updatedImage instanceof Blob) {
      croppedImage = createFileFromBlob(updatedImage)
    } else {
      croppedImage = updatedImage
    }
    setTimeout(async () => {
      const form = new FormData();
      form.append('vehicle_ad_overview', addDetails ? addDetails.vehicle_ad_overview_data.id : '')
      form.append('vehicle_feature_category', category)
      form.append('vehicle_category_image', croppedImage)

      const formDataObject = {};
      form.forEach((value, key) => {
        formDataObject[key] = value;
      });

      console.log('formDataObject', formDataObject);
      axios.defaults.headers.common["Accept"] = "application/json";
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      await axios.put(url, form, { headers: { 'Authorization': token } })
        .then((response) => {
          setLoading(false)
          console.log('response', response);
          if (response?.data.status === 1) {
            console.log('response', response);
            callAddDetailApi(addDetails.vehicle_ad_overview_data.id, '')
          } else {
            console.log('response', response);
            setShowAlert(true)
            setError(response.data.message)
          }
        })
        .catch(function (error) {
          console.log('error', error);
          setLoading(false)
          if (error.response) {
            setShowAlert(true)
            setError(error.response.data.message)
          }
        });
    }, 200);

  }


  const callAdGalleryCreateApi = async (category, updatedImages) => {
    setLoading(true);

    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_CREATE}`;

    // Iterate over each image and make an API call
    for (const file of updatedImages) {
      const form = new FormData();
      form.append('vehicle_ad_overview', addDetails ? addDetails.vehicle_ad_overview_data.id : '');
      form.append('vehicle_feature_category', category);
      form.append('vehicle_category_image', file);

      // Log the form data for debugging
      const formDataObject = {};
      form.forEach((value, key) => {
        formDataObject[key] = value;
      });
      console.log('formDataObject', formDataObject);

      try {
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        const response = await axios.post(url, form, { headers: { 'Authorization': token } });

        console.log('response', response);
        if (response?.data.status === 1) {
          // Handle successful response for each upload

        } else {
          console.log('response', response);
          setShowAlert(true);
          setError(response.data.message);
          setLoading(false);
          return false; // Exit on failure
        }
      } catch (error) {
        console.log('error', error);
        setLoading(false);
        if (error.response) {
          setShowAlert(true);
          setError(error.response.data.message);
        }
        return false; // Exit on failure
      }
    }

    // Set loading to false after all uploads are complete
    handleNextClick('gallery');
    setLoading(false);
  };

  const callAddDetailApi = (addId, btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addId}`;
    setTimeout(async () => {
      await axios.get(url, { headers: { 'Authorization': token } })
        .then((response) => {
          setLoading(false)
          if (response?.data.status === 1) {
            setAddDetails(response.data)
            const imagesData = response.data.vehicle_ad_gallery_data.vehicle_feature_categories
            const descriptionData = response.data.vehicle_ad_gallery_category_descriptions_data.vehicle_feature_category_descriptions
            const updatedSections = sections.map(section => {
              // Find matching image data
              let matchingImageData = {}
              if (imagesData) {
                matchingImageData = imagesData.find(data => data.vehicle_feature_category === section.title);
              }

              const updatedSection = { ...section };

              if (!isEmptyObject(matchingImageData)) {
                const imagobjArray = matchingImageData.vehicle_category_images.map(imageObj => ({
                  original_image: imageObj.vehicle_category_image,
                  updated_image: imageObj.vehicle_category_image,
                  id: imageObj.id,
                  isLoaded: false,
                  vehicle_category_order_id: imageObj.vehicle_category_order_id
                }));
                updatedSection.vehicle_category_images = imagobjArray;
              } else {
                updatedSection.vehicle_category_images = [];
              }
              // Find matching description data
              if (descriptionData) {
                const matchingDescData = descriptionData.find(data => data.vehicle_feature_category === section.title);
                if (matchingDescData) {
                  updatedSection.header = matchingDescData.category_heading;
                  updatedSection.description = matchingDescData.category_description;
                }
              }


              return updatedSection;
            });
            setSections(updatedSections);
            if (btnAction == 'next') {
              onNextPress()
            } else if (btnAction == 'preview') {
              openNewTab()
            } else if (btnAction == 'back') {
              onBackPress()
            } else if (btnAction == 'tabchange') {
              onClick()
            }
          } else {
            console.log('response', response);
            setShowAlert(true)
            setError(response.data.message)
          }
        })
        .catch(function (error) {
          console.log('error', error);
          setLoading(false)
          if (error.response) {
            setShowAlert(true)
            setError(error.response.data.message)
          }
        });
    }, 2000);

  }


  const callAddGalleryDeleteApi = async (galleryobj) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHICLE_ADD_GALLERY_DELETE}${galleryobj.id}/`;

    await axios.delete(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          console.log('responsedelete', response);
          callAddDetailApi(addDetails.vehicle_ad_overview_data.id, '')
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }


  return (
    <>

      <Row >
        {loading && (
          <div className="loader">
            <ClipLoader
              color={"#123abc"}
              loading={loading}
              size={50}
            />
          </div>
        )}
        <Col md={9}>
          <h6 className={'headertext2'}>{Strings.gallery}</h6>
          <div>
            {sections.map((section, sectionIndex) => {
              const currentIndex = sectionIndex;
              return (
                <Card className='card_gallery' key={sectionIndex}>
                  <Card.Header>
                    <button className='section_btn' onClick={() => toggleSection(sectionIndex)}>
                      <span style={{ width: 100, textAlign: 'left', height: '100%' }}>{section.title}</span>
                      {section.isOpen ? <img src={require('../../assets/up_arrow-black.png')} style={{ width: 15, height: 15 }} /> : <img src={require('../../assets/down_arrow.png')} style={{ width: 15, height: 15 }} />}
                    </button>
                  </Card.Header>
                  <Collapse in={section.isOpen}>
                    <div>
                      <Card.Body className='card-body-add'>
                        <Row>
                          <Col md={9} xxl={6} xl={7} lg={8} sm={8}>
                            <TopLabelInput type={'text'} label="Überschrift" value={section.header} onChange={e => handleInputChange(e.target.value, currentIndex)} />
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9} xxl={6} xl={7} lg={8} sm={8}>
                            <ReactQuill
                              theme="snow"
                              modules={modules}
                              className='quill_adds'
                              formats={formats}
                              value={section.description}
                              placeholder="Beschreibung ...."
                              onChange={(content) => { handleDescriptionChange(content, currentIndex) }}
                            />
                          </Col>
                        </Row>
                        {section.vehicle_category_images.length > 0 ?
                          <Row>
                            <Col md={12}>
                              <DragDropContext
                                onDragEnd={(result) => handleOnDragEnd(result, sectionIndex)}
                              >
                                <Droppable
                                  droppableId={`section-${sectionIndex}`}
                                  direction="horizontal"
                                >
                                  {(provided) => (
                                    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      {section.vehicle_category_images.map((imageData, imgIndex) => (
                                        <Draggable
                                          key={imgIndex}
                                          draggableId={`image-${sectionIndex}-${imgIndex}`}
                                          index={imgIndex}
                                        >
                                          {(provided) => (
                                            <div onMouseEnter={() => handleMouseEnter(sectionIndex, imgIndex)}
                                              onMouseLeave={handleMouseLeave}
                                              style={{
                                                ...provided.draggableProps.style,
                                                marginBottom: 10, flex: '0 0 auto'
                                              }}
                                              key={imgIndex} sm={2}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              ref={provided.innerRef}

                                            >
                                              <OverlayTrigger
                                                show={showDeletePopover.sectionIndex !== null ? showDeletePopover.sectionIndex === sectionIndex && showDeletePopover.imageIndex === imgIndex : popoverOpen.sectionIndex === sectionIndex && popoverOpen.imageIndex == imgIndex}
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay=
                                                {
                                                  showDeletePopover.sectionIndex !== null ? (
                                                    <Popover id={`deletepopover-${sectionIndex}-${imgIndex}`} placement="top">
                                                      <div className='popover-content'>
                                                        <span style={{ color: 'white', fontSize: 15, paddingRight: 10, paddingLeft: 10 }}>Loschen?</span>
                                                        <Button variant="danger" className="m-1" onClick={() => handleConfirmDelete(sectionIndex, imgIndex)}>
                                                          <img src={require('../../assets/tick-white.png')} style={{ width: 15, height: 15 }} alt="TickIcon"></img>
                                                        </Button>
                                                        <Button className="m-1" onClick={() => handleCloseDeletePopover(sectionIndex, imgIndex)}>
                                                          <img src={require('../../assets/close-white.png')} style={{ width: 15, height: 15 }} alt="CloseIcon"></img>
                                                        </Button>
                                                      </div>
                                                    </Popover>

                                                  ) : (
                                                    <Popover id={`popover-${sectionIndex}-${imgIndex}`} placement="top">
                                                      <div className='popover-content'>
                                                        <Button className="m-1" onClick={() => handleEditClick(sectionIndex, imgIndex)}>
                                                          <img src={require('../../assets/edit_white.png')} style={{ width: 15, height: 15 }} alt="EditIcon"></img>
                                                        </Button>
                                                        {/* <Button className="m-1" onClick={() => handleEditText(sectionIndex, imgIndex)}>
                                                <RiMenu2Line size={15} color='white' />
                                              </Button> */}
                                                        <Button className="m-1" onClick={() => handleDeleteClick(sectionIndex, imgIndex)}>
                                                          <img src={require('../../assets/delete.png')} style={{ width: 15, height: 15 }} alt="DeleteIcon"></img>
                                                        </Button>
                                                      </div>
                                                    </Popover>)
                                                }
                                              >
                                                <div className='add-dashed-view'>
                                                  <img style={{ objectFit: 'contain' }} onLoad={(e) => handleImageLoaded(e.target.src, sectionIndex, imgIndex)} src={imageData.updated_image instanceof File || imageData.updated_image instanceof Blob ? URL.createObjectURL(imageData.updated_image) : imageData.updated_image} className='add-upload_image' alt={`Image ${imgIndex + 1}`} />
                                                </div>
                                              </OverlayTrigger>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      <div style={{ flex: '0 0 auto' }} className='add-dashed-view' onClick={() => inputRefs.current[sectionIndex].current.click()}>
                                        <input multiple={true} type="file" accept="image/*" style={{ display: 'none' }} onChange={(e) => {
                                          handleImageUpload(e, currentIndex)
                                        }} ref={inputRefs.current[sectionIndex]} />
                                        <img className='placeholder_img' src={require('../../assets/placeholder.png')} />
                                      </div>
                                    </div>)}
                                </Droppable>
                              </DragDropContext>
                              {imageErros[sectionIndex] && imageErros[sectionIndex].length > 0 && (
                                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
                                  <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} />
                                  <div className="error-message">{`Folgende Bilder haben Probleme:\n\n${imageErros[sectionIndex].join('\n')}`}</div>
                                </div>
                              )}
                            </Col>
                          </Row>
                          : <div className='add-dashed-view' onClick={() => inputRefs.current[sectionIndex].current.click()}>
                            <input multiple={true} type="file" accept="image/*" style={{ display: 'contents' }} onChange={(e) => {
                              handleImageUpload(e, currentIndex)
                            }} ref={inputRefs.current[sectionIndex]} />
                            <img className='placeholder_img' src={require('../../assets/placeholder.png')} />
                          </div>}
                      </Card.Body>

                    </div>
                  </Collapse>
                </Card>
              )
            }
            )}
          </div>
          <Row style={{ display: 'inline-flex', flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
            <Col>
              <button className="add_back_btn" style={{ minWidth: 120, marginBottom: 10 }} onClick={handleBackClick}>{Strings.back}</button>
            </Col>
            <Col>
              <button className="next-btn" style={{ minWidth: 120, marginBottom: 10 }} onClick={() => { handleNextClick('next') }}>{Strings.next}</button>
            </Col>
            <Col>
              <button className="add_preview_btn" style={{ minWidth: 120, marginBottom: 10 }} onClick={() => { handleNextClick('preview') }} >{Strings.preview}<img src={require('../../assets/desktop.png')} style={{ width: 15, height: 15, marginLeft: 5 }} /></button>
            </Col>
          </Row>

        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal} style={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
        <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* <ReactCrop
            crop={crop}
            onChange={handleCropChange}
            onImageLoaded={handleImageLoaded}
          >
            <img
              src={imageUrl}
              alt=""
              style={{ maxHeight: '30vh', width: '60vh', objectFit: 'contain' }}
              onLoad={(e) => setImageRef(e.target)}
            />
          </ReactCrop> */}
          <div style={{ height: "30vh", width: "60vh" }}>
            <Cropper
              ref={cropperRef}
              src={imageUrl}
              style={{ height: "100%", width: '100%' }}
              guides={true}
              scale={1}
              viewMode={1}
              cropend={onCrop}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
          <Button className="next-btn" onClick={saveImgCrop}>Übernehmen!</Button>
        </Modal.Footer>
      </Modal>
      {showalert &&
        <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
      }
      {showImageTextEditor && <ImageTextEditor title={sections[sectionindex].title} imageData={{ 'header': sections[sectionindex].header, 'description': sections[sectionindex].description }} images={sections[sectionindex].vehicle_category_images} showHeader={true} showModal={showImageTextEditor} closeModal={() => setShowImageTextEditor(false)} />}
    </>
  )
}

export default AdGallery