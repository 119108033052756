import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DataContext } from '../utilities/DataContext';

const ScrollToTop = () => {
    const { pathname } = useLocation();
    // const { pageTitleAvailable } = useContext(DataContext);

    useEffect(() => {
        // if (document.title !== 'campervanspot') {
        //     console.log('document.title', document.title)
        //     setTimeout(() => {
        //         window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        //     }, 0);
        // }

        console.log('scrolled>>>>')
        setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }, 0);
    }, [pathname]);

    return null;
};

export default ScrollToTop;