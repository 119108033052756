import React, { useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import '../UserPage/UserSelectionPage.css'
import '../../app.css'
import Footer from '../../Components/Footer/Footer';
import { Strings, usePageTracking } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { useAnalytics } from '../../utilities/AnalyticsContext';

const UserSelectionPage = () => {
    const { markTitleUpdated } = useAnalytics()
    const navigate = useNavigate();
    const { setUserType, setPageTitleAvailable } = useContext(DataContext)
    const pageTitle = 'Registrierung Business/Privat';
    const previousTitleRef = useRef();

    useEffect(() => {
        document.title = 'Registrierung Business/Privat'
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = ((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    });


    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);
    // useEffect(() => {
    //     document.title = 'Registrierung Business/Privat';
    //     if (window.trackPageTitleChange) {
    //         window.trackPageTitleChange('Registrierung Business/Privat');
    //     }
    // }, [])

    const gotoRegistration = (type) => {
        if (type === 'company') {
            navigate('/registration')
            setUserType('business-user')
        } else {
            navigate('/private_user_registration')
            setUserType('private-user')
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'white', height: '15vmin' }}>
                <img
                    src={require("../../../src/assets/logo.webp")}
                    alt='logo'
                    className="d-inline-block align-top"
                    onClick={() => { navigate('/home') }}
                />
            </div>
            <div style={{ backgroundColor: 'white', }} >
                <div style={{
                    padding: '10vh',
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/reg_back.jpg"})`, backgroundPosition: 'center', backgroundSize: 'cover', alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'
                }}>
                    <span className='headersignup'>
                        {Strings.registration} <div className='borderstyle'></div> </span>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '5vmin' }}>
                        <Col className='userselection_shadowdiv' xxl={4} md={7} xl={5} lg={5} sm={8} xs={12}>
                            <span className='btnnext'>
                                Privatnutzer
                            </span>
                            <div style={{ marginTop: 20 }}>
                                <span className='main-header'>Deine Vorteile:</span>
                                <ul>
                                    <li className='subtext'><span>{'Eine vielfältige Auswahl an Camper Vans für jeden Reisezweck, von Offroad-Abenteuern bis zum Strandurlaub.'}</span></li>
                                    <li className='subtext'><span>{'Detaillierte Informationen zu allen Anbietern und Fahrzeugen'}</span></li>
                                    <li className='subtext'><span>{`Kontinuierlich aktualisierte Auswahl an Campervans`}</span></li>
                                </ul>
                            </div>
                            <div style={{ position: 'absolute', bottom: '5vmin', cursor: 'pointer' }} onClick={() => { gotoRegistration('private-user') }}>
                                <span className='subtext' style={{ textDecoration: 'underline' }}>Als Privatnutzer fortfahren</span>
                            </div>
                        </Col>
                        <Col className='userselection_shadowdiv' xxl={4} md={7} xl={5} lg={5} sm={8} xs={12}>
                            <span className='btnnext' >
                                Unternehmen
                            </span>
                            <div style={{ marginTop: 20 }}>
                                <span className='main-header'>Eure Vorteile:</span>
                                <ul>
                                    <li className='subtext'>  <span>{'Attraktive und Konsumentengerechte Präsentation Ihres Unternehmens'}</span></li>
                                    <li className='subtext'> <span>{`Wir bewerben die bei uns gelisteteten Unternehmen regelmäßig auf Social-Media`}</span></li>
                                    <li className='subtext'><span>{`Grundlage zur Vermarktung Ihrer Fahrzeuge auf unserer Plattform`}</span></li>
                                </ul>
                            </div>
                            <div style={{ position: 'absolute', bottom: '5vmin', cursor: 'pointer' }} onClick={() => { gotoRegistration('company') }}>
                                <span className='subtext' style={{ textDecoration: 'underline' }}>Als Unternehmen fortfahren</span>
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UserSelectionPage;