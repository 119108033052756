import React, { useState, useEffect } from 'react';
import './DropDownInput.css'; // Import your CSS file for styling


const DropDownInput = ({ label, value, onChange, type, short, dropDownData, onSelect, singleselection, onToggle, isOpen }) => {

    const [singleSelectedItem, setSingleSelectedItem] = useState(null);
    const [multiSelectedItems, setMultiSelectedItems] = useState([]);

    useEffect(() => {
        setSingleSelectedItem(value)
    }, [value]);

    // const toggleDropdown = () => {

    //     setIsOpen(!isOpen);
    // };

    const handleSingleSelectionItemClick = (item) => {
        console.log('item', item)
        setSingleSelectedItem(item);
        onSelect(item);
        // toggleDropdown();
        onToggle();
    };

    const handleMultiSelectionItemClick = (item) => {
        const index = multiSelectedItems.indexOf(item);
        let updatedSelectedItems = [...multiSelectedItems];
        if (index === -1) {
            updatedSelectedItems.push(item);
        } else {
            updatedSelectedItems.splice(index, 1);
        }
        setMultiSelectedItems(updatedSelectedItems);

        onSelect(item);
        // toggleDropdown();
    };

    // const handleDropdownItemClick = (item) => {
    //     const index = selectedItems.indexOf(item);
    //     let updatedSelectedItems = [...selectedItems];
    //     if (index === -1) {
    //         updatedSelectedItems.push(item);
    //     } else {
    //         updatedSelectedItems.splice(index, 1);
    //     }
    //     setSelectedItems(updatedSelectedItems);
    //     onSelect(item);
    //     console.log('item', item)
    //     toggleDropdown();
    // };


    return (
        <>
            <div className="dropdown_overview">
                <label className='label_dropdown'>{label}</label>
                <div className='div_dropdown'>
                    {/* <input disabled={label === 'Typ' || label === 'Verfügbarkeit' || label === 'Kraftstoffart' || label === 'Getriebe' || label === 'Schadstoffklasse' || label === 'Umweltplakette' || label === 'Klimatisierung' || label === 'Einparkhilfe' || label === 'Farbe'} className='input_dropdown' style={{ width: '80%' }} type={type} value={value} onInput={onChange}></input> */}
                    <input disabled={label !== 'Modell'} className='input_dropdown' style={{ width: '80%' }} type={type} value={value} onInput={onChange}></input>
                    <div
                        className="dropdown-icon"
                        style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                        onClick={onToggle}
                    >
                        {/* <IoIosArrowDown color='gray' /> */}
                        <img src={require('../../assets/down_arrow.png')} style={{ height: 15, width: 15 }}></img>
                    </div>

                </div>
                {isOpen && (
                    <div className="dropdown-menu-details">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 8, paddingTop: 5 }} onClick={() => { handleSingleSelectionItemClick('') }}>
                            <img src={require('../../assets/black_tick.png')} style={{ width: 15, height: 15 }}></img>
                            <span className='headertext_dropdown ' style={{ paddingTop: 5, paddingBottom: 5 }}>{label}</span>
                        </div>

                        {dropDownData.map((item, index) => (
                            <div style={{ backgroundColor: !singleselection ? multiSelectedItems.includes(item.label) && 'orange' : singleSelectedItem === item && 'orange', borderRadius: 5, color: !singleselection ? multiSelectedItems.includes(item.label) && 'white' : singleSelectedItem === item && 'white', paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }} key={index} className="dropdown-item" onClick={() => { singleselection ? handleSingleSelectionItemClick(item) : handleMultiSelectionItemClick(item.label) }}>
                                {item.label ? item.label : item}
                                {/* {!singleselection ? multiSelectedItems.includes(item.label) && <IoIosCheckmarkCircle color='green' /> : singleSelectedItem === item && <IoIosCheckmarkCircle color='green' />} */}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default DropDownInput;