import React, { useState } from 'react';
import './TopLabelInput.css'; // Import your CSS file for styling
import en from 'world_countries_lists/data/countries/en/world.json';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import { deviceLocale } from '../../utilities/constants';



const TopLabelInput = ({ label, value, onChange, type, short, minimumNumber, inputtype }) => {

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // Get the device's locale
  const numberFormat = new Intl.NumberFormat(deviceLocale);
  const handleInputChange = (event) => {
    if (type === 'number' && inputtype !== 'price' && inputtype !== 'year') {
      const newValue = event.target.valueAsNumber;
      if (newValue < minimumNumber) {
        onChange({ target: { value: minimumNumber } }); // Set value to minimumNumber if less than minimumNumber
      } else {
        onChange(event);
      }
    } else if (inputtype === 'integer') {
      const formattedValue = event.target.value;
      const strippedValue = formattedValue.replace(/[^0-9.]/g, '');
      const parsedValue = parseInt(strippedValue.replace(/\./g, ''), 10);
      if (!isNaN(parsedValue)) {
        if (parsedValue < minimumNumber) {
          onChange({ target: { value: numberFormat.format(minimumNumber) } }); // Set value to minimumNumber if less than minimumNumber
        } else {
          onChange({ target: { value: numberFormat.format(parsedValue) } });
        }
      } else {
        onChange({ target: { value: '' } }); // Clear the input if it's not a valid number
      }

    } else if (inputtype === 'year') {
      const sanitizedValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters

      // Check if the input has reached 4 digits
      if (sanitizedValue.length <= 4) {
        const yearValue = sanitizedValue;

        // If the input has 4 digits, validate the year
        if (yearValue.length === 4) {
          const currentYear = new Date().getFullYear();
          const futureYear = currentYear + 1;
          if (yearValue <= 1900) {
            onChange({ target: { value: 1900 } }); // Accept the value
          } else if (yearValue >= 1900 && yearValue <= futureYear) {
            onChange({ target: { value: yearValue } }); // Accept the value
          } else if (yearValue > futureYear) {
            onChange({ target: { value: futureYear.toString() } }); // Set to maximum future year
          } else {
            onChange({ target: { value: '' } }); // Clear invalid value
          }
        } else {
          // Allow partial input if less than 4 digits
          onChange({ target: { value: yearValue } });
        }
      }
    } else {
      onChange(event);
    }
  };

  const handleYearKeyDown = (e) => {
    if (inputtype === 'year') {
      // Allow only numbers, Backspace, and Delete keys
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

      if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
        e.preventDefault(); // Prevent any other input
      }
    }
  };




  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      {type === 'tel' ?
        <div>
          <ConfigProvider locale={en} areaMapper={(area) => {
            return {
              ...area,
            };
          }}
          >
            <CountryPhoneInput
              maxLength={14}
              name='phone'
              placeholder='Telefonnummer'
              style={{ marginBottom: 15 }}
              inline
              defaultValue={{ short: short }}
              value={{ phone: value.replace(/^\+\d+\s*/, ''), code: value.split(' ')[0], short: short }}
              onChange={onChange}
            />
          </ConfigProvider>
        </div>
        :
        <div className="top-label-input">
          <label className='label_flot'>{label}</label>
          {type === 'textarea' ? (
            <textarea style={{ padding: 10 }} value={value} onChange={onChange}></textarea>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className='toplabaleinput'
                type={isPasswordVisible ? "text" : type}
                min={minimumNumber || 0}
                value={value}
                style={{ paddingRight: 35 }}
                onInput={handleInputChange}
                onKeyDown={handleYearKeyDown}
              />
              {type === 'password' && (
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: 10,
                    cursor: 'pointer',
                  }}
                >
                  {isPasswordVisible ? <img src={require('../../assets/eye_open.png')} style={{ width: 20, height: 20 }} /> : <img src={require('../../assets/eye_close.png')} style={{ width: 20, height: 20 }} />}
                </span>
              )}
            </div>
          )}
        </div>
      }
    </>
  );
};

export default TopLabelInput;
