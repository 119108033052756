import React, { createContext, useState, useEffect } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {

  const initialData = {
    userData: {},
    companyInfo: {},
    token: '',
    adddetails: {},
    isLoggedIn: false,
    businessForm1data: {},
    businessForm2data: {},
    tab: 'overview',
    userType: '',
    noOfBedErr: '',
    messageCount: 0,
    chatData: [],
    flagsData: [],
    isAdmin: false,
    consentGranted: false,
    pageTitleAvailable: false
    // Other initial data properties...
  };

  const [userData, setUserData] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('userData');
    if (storedData === 'undefined') {
      return initialData.userData;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.userData;
    }

  });

  const [pageTitleAvailable, setPageTitleAvailable] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('pageTitleAvailable');
    if (storedData === 'undefined') {
      return initialData.pageTitleAvailable;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.pageTitleAvailable;
    }

  });


  const [consentGranted, setConsentGranted] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('consentGranted');
    if (storedData === 'undefined') {
      return initialData.consentGranted;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.consentGranted;
    }

  });


  const [companyInfo, setCompanyInfo] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('companyInfo');
    if (storedData === 'undefined') {
      return initialData.companyInfo;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.companyInfo;
    }

  });


  const [userType, setUserType] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('userType');
    if (storedData === 'undefined') {
      return initialData.userType;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.userType;
    }

  });

  const [isAdmin, setIsAdmin] = useState(() => {
    const storedData = localStorage.getItem('isAdmin');
    return storedData ? JSON.parse(storedData) : false; // Default to false if not set
  });

  const [businessForm1data, setBusinessForm1data] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('businessForm1data');
    if (storedData === 'undefined') {
      return initialData.businessForm1data;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.businessForm1data;
    }
  });
  const [businessForm2data, setBusinessForm2data] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('businessForm2data');
    if (storedData === 'undefined') {
      return initialData.businessForm2data;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.businessForm2data;
    }
  });

  const [token, setToken] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('token');
    if (storedData === 'undefined') {
      return initialData.token;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.token;
    }
  });


  const [tab, setTab] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('tab');
    if (storedData === 'undefined') {
      return initialData.tab;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.tab;
    }
  });

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('isLoggedIn');
    if (storedData === 'undefined') {
      return initialData.isLoggedIn;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.isLoggedIn;
    }
  });

  const [addDetails, setAddDetails] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('add_details');
    if (storedData === 'undefined') {
      return initialData.adddetails;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.adddetails;
    }
  });


  const [noOfBedErr, setNoOfBedErr] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('noOfBedErr');
    if (storedData === 'undefined') {
      return initialData.noOfBedErr;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.noOfBedErr;
    }

  });


  const [messageCount, setMessageCount] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('messageCount');
    if (storedData === 'undefined') {
      return initialData.messageCount;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.messageCount;
    }

  });

  const [chatData, setChatData] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('chatData');
    if (storedData) {
      return initialData.chatData;
    } else {
      return storedData ? JSON.parse(chatData) : initialData.chatData;
    }

  });

  const [flagsData, setFlagsData] = useState(() => {
    // Load data from localStorage on initial render
    const storedData = localStorage.getItem('flagsData');
    if (storedData === 'undefined') {
      return initialData.flagsData;
    } else {
      return storedData ? JSON.parse(storedData) : initialData.flagsData;
    }

  });


  useEffect(() => {
    console.log('pageTitleAvailable', pageTitleAvailable)
    localStorage.setItem('pageTitleAvailable', JSON.stringify(pageTitleAvailable));
  }, [pageTitleAvailable]);

  useEffect(() => {
    console.log('consentGranted', consentGranted)
    localStorage.setItem('consentGranted', JSON.stringify(consentGranted));
  }, [consentGranted]);

  useEffect(() => {
    console.log('userData', userData)
    localStorage.setItem('userData', JSON.stringify(userData));
  }, [userData]);

  useEffect(() => {
    console.log('companyInfo', companyInfo)
    localStorage.setItem('companyInfo', JSON.stringify(companyInfo));
  }, [companyInfo]);

  useEffect(() => {
    console.log('userType', userType)
    localStorage.setItem('userType', JSON.stringify(userType));
  }, [userType]);

  useEffect(() => {
    localStorage.setItem('isAdmin', JSON.stringify(isAdmin));
  }, [isAdmin]);

  useEffect(() => {
    console.log('isLoggedIn', isLoggedIn)
    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn));

  }, [isLoggedIn]);

  useEffect(() => {
    localStorage.setItem('tab', JSON.stringify(tab));
  }, [tab]);

  useEffect(() => {
    localStorage.setItem('token', JSON.stringify(token));
  }, [token]);

  useEffect(() => {
    localStorage.setItem('add_details', JSON.stringify(addDetails));
  }, [addDetails]);

  useEffect(() => {
    localStorage.setItem('businessForm1data', JSON.stringify(businessForm1data));
  }, [businessForm1data]);
  useEffect(() => {
    localStorage.setItem('businessForm2data', JSON.stringify(businessForm2data));
  }, [businessForm2data]);

  useEffect(() => {
    console.log('messageCount', messageCount)
    localStorage.setItem('messageCount', JSON.stringify(messageCount));
  }, [messageCount]);


  useEffect(() => {
    localStorage.setItem('noOfBedErr', JSON.stringify(noOfBedErr));
  }, [noOfBedErr]);

  useEffect(() => {
    localStorage.setItem('chatData', JSON.stringify(chatData));
  }, [chatData]);



  useEffect(() => {
    localStorage.setItem('flagsData', JSON.stringify(flagsData));
  }, [flagsData]);


  return (
    <DataContext.Provider value={{ pageTitleAvailable, setPageTitleAvailable, consentGranted, setConsentGranted, userData, setUserData, companyInfo, setCompanyInfo, userType, setUserType, isAdmin, setIsAdmin, tab, setTab, token, setToken, isLoggedIn, setIsLoggedIn, businessForm1data, setBusinessForm1data, businessForm2data, setBusinessForm2data, addDetails, setAddDetails, noOfBedErr, setNoOfBedErr, messageCount, setMessageCount, chatData, setChatData, flagsData, setFlagsData }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };