import React, { useContext, useEffect, useRef, useState } from "react";

import { Row, Col } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import "../../app.css";
import "../PrivateUserRegistration/PrivateUserRegistration.css"
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../utilities/DataContext";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "quill/dist/quill.snow.css";
import ReactGA from 'react-ga4';
import { debounce } from 'lodash';
import { useAnalytics } from "../../utilities/AnalyticsContext";

const RegistrationSuccess = () => {
    const { markTitleUpdated } = useAnalytics()
    const { setPageTitleAvailable } = useContext(DataContext)
    const navigate = useNavigate();
    const pageTitle = 'Anmeldeerfolg'
    const previousTitleRef = useRef();

    useEffect(() => {
        document.title = 'Anmeldeerfolg'
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);


    return (
        <>
            <Header />
            <div style={{ position: 'absolute', marginTop: 75, height: '90%', right: 0, left: 0, display: 'flex', justifyContent: 'center' }}>
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col xxl={8} xl={8} lg={10} md={11} xs={10} style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: '10vmin', paddingRight: '10vmin' }}>
                        <h1 className="headertext1 mb4" style={{ fontSize: 25 }}>
                            Vielen Dank für deine Registrierung!
                        </h1>
                        <p className="successtext">
                            Wir haben dir eine E-Mail zur Bestätigung deiner Adresse und zur Aktivierung deines Accounts geschickt. Bitte überprüfe auch den Spam-Ordner, falls unsere Mail dort gelandet ist. Nach der Verifizierung kehre bitte zu dieser Seite zurück.
                        </p>
                    </Col>
                    <Col xxl={3} xl={3} lg={0} md={0} xs={0} className="col-reg">
                        <div style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + "/registration_bg.jpg"})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            height: '100%'
                        }} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default RegistrationSuccess;