// UserRoutes.js
import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import HomeScreen from "./Home/Home";
import PrivateRoute from "./PrivateRoute";
import LoginError from "./ErrorPage/LoginError";
import AuthError from "./ErrorPage/AuthErrorPage"
import SearchScreen from './Search/Search'
import FinisherScreen from "./Finisher/Finisher"
import RegistrationScreen from "./Registration/Registration"
import BusinessFormPage from "./BusinessForm/BusinessForm"
import BusinessPage from './BusinessPage/BusinessPage'
import AdsMainPage from './Ads/AdsMainPage'
import AdPreviewPage from './AdPreviewPage/AdPreviewPage'
import DashBoard from './DashBoard/DashBoard'
import AdsList from './AdsList/AdsList'
import ChangePassword from './ChangePassword/ChangePassword'
import SuccessPage from './SuccessPage/SuccessPage'
import ErrorPage from '../screens/ErrorPage/ErrorPage'
import UserSelectionPage from './UserPage/UserSelectionPage'
import PrivateUserRegistration from './PrivateUserRegistration/PrivateUserRegistration'
import Profile from './UserProfile/Profile'
import Messaging from './Messaging/Messaging'
import RegistrationSuccess from './PrivateUserRegistration/RegistrationSuccess'
import OrderSummary from "./OrderSummary/OrderSummary";
import StripeSuccess from "./StripeSuccess/StripeSuccess";

// Import other components as needed

const UserRoutes = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<Navigate to="/home" replace />} />
                <Route path="/home" element={<HomeScreen />} />
                <Route path="/registrationtypeselection" element={<UserSelectionPage />} />
                <Route path="/registration" element={<RegistrationScreen />} />
                <Route path="/sichere-meine-unternehmensseite-auf-campervanspot" element={<RegistrationScreen />} />
                <Route path="/private_user_registration" element={<PrivateUserRegistration />} />
                <Route path="/businesspage/:companyName" element={<BusinessPage />} />
                {/* <Route path="/businessform" element={<BusinessFormPage />} /> */}
                <Route path="/businessform" element={<PrivateRoute element={<BusinessFormPage />} redirectTo="/login" requiredRole="business_user" message='businessform' />} />
                <Route path="/search" element={<SearchScreen />} />
                {/* <Route path="/stripe-success" element={<StripeSuccess />} /> */}
                <Route path="/businesses" element={<FinisherScreen />} />
                {/* <Route path="/adcreate" element={<AdsMainPage />} /> */}
                <Route path="/adcreate" element={<PrivateRoute element={<AdsMainPage />} redirectTo="/login" message='adcreate' />} />
                <Route path="/ad/:adId" element={<AdPreviewPage />} />
                {/* <Route path="/dashboard" element={<DashBoard />} /> */}
                <Route path="/dashboard" element={<PrivateRoute element={<DashBoard />} redirectTo="/login" message='dashboard' />} />
                {/* <Route path="/adslist" element={<AdsList />} /> */}
                <Route path="/adslist" element={<PrivateRoute element={<AdsList />} redirectTo="/login" message='adslist' />} />
                {/* <Route path="/ordersummary" element={<PrivateRoute element={<OrderSummary />} redirectTo="/login" message='ordersummary' />} /> */}
                <Route path="/login" element={<LoginError />} />
                <Route path="/auth-error" element={<AuthError />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                {/* <Route path="/changepassword" element={<PrivateRoute element={<ChangePassword />} redirectTo="/login" message='changepassword' />} /> */}
                <Route path="/successpage" element={<SuccessPage />} />
                {/* <Route path="/successpage" element={<PrivateRoute element={<SuccessPage />} redirectTo="/login" message='successpage' />} /> */}
                {/* <Route path="/profile" element={<Profile />} /> */}
                <Route path="/profile" element={<PrivateRoute element={<Profile />} redirectTo="/login" requiredRole="private_user" message='profile' />} />
                {/* <Route path="/chat" element={<Messaging />} /> */}
                <Route path="/chat" element={<PrivateRoute element={<Messaging />} redirectTo="/login" message='chat' />} />
                <Route path="/registration_success" element={<PrivateRoute element={<RegistrationSuccess />} redirectTo="/login" message='registration_success' />} />
                {/* <Route path="/registration_success" element={<RegistrationSuccess />} /> */}
                <Route path='*' element={<ErrorPage />} />
            </Routes>
        </Suspense>
    );
}

export default UserRoutes;
