import React, { useContext, useEffect, useRef } from "react";
import '../ErrorPage/ErrorPage.css'
import { debounce } from "lodash";
import { usePageTracking } from "../../utilities/constants";
import ReactGA from 'react-ga4';
import { DataContext } from "../../utilities/DataContext";
import { useAnalytics } from "../../utilities/AnalyticsContext";

export default function NotFound() {
    const { markTitleUpdated } = useAnalytics()
    const { setPageTitleAvailable } = useContext(DataContext)
    const previousTitleRef = useRef();
    const pageTitle = 'Auth Error'

    useEffect(() => {
        document.title = 'Auth Error'
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);

    return (
        <div className="errordiv" >
            < img
                src={require("../../assets/logo.webp")}
                width="200vh"
                height="200vw"
                alt='logo'
                style={{ objectFit: 'contain' }}
                className="d-inline-block align-top"
            />
            <p style={{ marginBottom: 20, marginTop: 20 }} className='error_string'>
                404: Es tut uns sehr leid, aber wir konnten die von Dir gesuchte Seite nicht finden. Bitte starte nochmal von hier:
                <a href="https://campervanspot.com" style={{ textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer"> https://campervanspot.com</a>
            </p>
        </div>

    )
}