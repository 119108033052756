import React, { useEffect } from 'react';
import './MeasurementInput.css'; // Import your CSS file for styling
import { deviceLocale } from '../../utilities/constants';

const MeasurementInput = ({ label, value, onChange, type, measurement, minimumNumber, inputtype }) => {
    // Get the device's locale
    const numberFormat = new Intl.NumberFormat(deviceLocale);
    const decimalSeparator = numberFormat.format(1.1).charAt(1);
    const thousandSeparator = numberFormat.format(1000).charAt(1);

    const handleInputChange = (event) => {
        if (inputtype === "integer") {
            const formattedValue = event.target.value;
            const strippedValue = formattedValue.replace(/[^0-9.]/g, '');
            const parsedValue = parseInt(strippedValue.replace(/\./g, ''), 10);
            if (!isNaN(parsedValue)) {
                if (parsedValue < minimumNumber) {
                    onChange({ target: { value: numberFormat.format(minimumNumber) } }); // Set value to minimumNumber if less than minimumNumber
                } else {
                    onChange({ target: { value: numberFormat.format(parsedValue) } });
                }
            } else {
                onChange({ target: { value: '' } }); // Clear the input if it's not a valid number
            }
        } else if (inputtype === "decimal") {
            const inputValue = event.target.value;
            // Allow numbers and the locale-specific decimal separator
            const regex = new RegExp(`[^0-9${decimalSeparator}]`, 'g');
            let strippedValue = inputValue.replace(regex, '');

            // Ensure only one decimal separator is present
            const parts = strippedValue.split(decimalSeparator);
            if (parts.length > 2) {
                strippedValue = parts[0] + decimalSeparator + parts.slice(1).join('');
            }

            // Limit to two decimal places
            if (parts.length === 2) {
                parts[1] = parts[1].substring(0, 2); // Limit to two digits after the decimal
                strippedValue = parts.join(decimalSeparator);
            }

            if (parts[0]) {
                const integerPart = parts[0]  // Default to '0' if empty
                const formattedIntegerPart = numberFormat.format(parseInt(integerPart.replace(new RegExp(`\\${thousandSeparator}`, 'g'), ''), 10));
                strippedValue = formattedIntegerPart + (parts.length === 2 ? decimalSeparator + parts[1] : '');
            }


            onChange({ target: { value: strippedValue } });
        } else {
            if (type === 'number') {
                const newValue = event.target.valueAsNumber;
                if (newValue < minimumNumber) {
                    onChange({ target: { value: minimumNumber } }); // Set value to minimumNumber if less than minimumNumber
                } else {
                    onChange(event);
                }
            } else {
                onChange(event);
            }
        }
    };

    return (
        <>
            <div className="measurement_overview">
                <label>{label}</label>
                <div className="div_measurement">
                    <input className='input_measurement' style={{ width: '80%' }} type={type} value={value} min={0} onInput={handleInputChange}></input>
                    <div
                        className="measurementinput-dropdown-icon"
                        style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', cursor: 'pointer', }}
                    >
                        {measurement}
                    </div>
                </div>

            </div>
        </>
    );
};

export default MeasurementInput;