import React, { lazy, Suspense, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { DataContext } from "../utilities/DataContext";
import ScrollToTop from "../Components/ScrollToTop";
import AdminRoutes from "./AdminRoutes";
import UserRoutes from "./UserRoutes";
import ThirdPartyRoutes from "./ThirdPartyRoute";



const MainNavigator = () => {
    const { isLoggedIn, isAdmin } = useContext(DataContext);
    const currentUrl = window.location.href;
    const isHome = currentUrl.includes('/home');
    const isUserAds = currentUrl.includes('/userad');
    const isAdminDashboard = currentUrl.includes('/admin');
    useEffect(() => {

    }, [])

    return (
        <>
            <ScrollToTop />
            {isAdminDashboard ? <AdminRoutes /> : isUserAds ? <ThirdPartyRoutes /> : <UserRoutes />}
        </ >
    );
}

export default MainNavigator;