import React, { useContext, useEffect, useRef } from 'react';
import '../SuccessPage/SucessPage.css'
import { useNavigate } from 'react-router-dom';
import '../../app.css'
import { usePageTracking } from '../../utilities/constants';
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { DataContext } from '../../utilities/DataContext';
import { useAnalytics } from '../../utilities/AnalyticsContext';

const SuccessPage = () => {
    const { markTitleUpdated } = useAnalytics()
    const { setPageTitleAvailable } = useContext(DataContext)

    const navigate = useNavigate();
    const previousTitleRef = useRef();
    const pageTitle = 'Erfolgreich Passwort geändert'

    useEffect(() => {
        document.title = 'Erfolgreich Passwort geändert'
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);


    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);

    useEffect(() => {
        const timeout = setTimeout(() => {
            // Navigate to Screen B after 3 seconds
            navigate('/home');
        }, 5000);
        // Clean up the timeout when the component unmounts or when navigating away
        return () => {
            clearTimeout(timeout)
        };
    }, [navigate]);

    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>

            <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 20, flexDirection: 'column' }}>
                <img
                    src={require("../../assets/logo.webp")}
                    width="180vh"
                    height="120vw"
                    alt='logo'
                    style={{ objectFit: 'contain' }}
                    className="d-inline-block align-top"
                />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10vh' }}>
                    <img
                        src={require("../../assets/successtick.png")}
                        height={40}
                        width={40}
                        style={{ marginRight: 20, objectFit: 'contain', marginLeft: 20 }}
                        className="d-inline-block align-top"
                    />
                    <span style={{ marginBottom: 20, marginTop: 20 }} className='success_text'>Vielen Dank für Deine Passwort Änderung. Du kannst Dich jetzt mit Deinem neuen Passwort anmelden</span>
                </div>


            </div>
        </div>
    );
};

export default SuccessPage;