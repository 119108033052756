import React, { useState, useRef, useCallback } from "react";
import { Button, Card, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import "./ImageCard.css";
import '../../app.css'
import "react-image-crop/dist/ReactCrop.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ImageCard = ({
  imageUrl,
  onDelete,
  index,
  onSaveCroppedImage,
  updatedImage,
  onEditText,
  onLoadImage,
}) => {

  const cropperRef = useRef(null);
  const [imgRef, setImgRef] = useState(null);
  const [scale, setScale] = useState(1)

  const [showModal, setShowModal] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [image, setImage] = useState(imageUrl);
  const [croppedImage, setCroppedImage] = useState(null);


  const handleMouseEnter = () => {
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
    // setShowDeletePopover(false)
  };

  const handleEditClick = () => {
    setShowPopover(false);
    setShowModal(true)
    // const img = new Image();
    // img.onload = handleImageLoaded;
    // img.src = imageUrl;

  };

  const handleImageLoaded = async (e) => {
    if (imgRef === null) {
      let img = e.target.src;
      console.log("heroImage", img);
      try {
        const response = await fetch(img);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        img = imgUrl;

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imgElement = new Image();
          imgElement.src = reader.result;

          imgElement.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set maximum width and height (adjust as needed)
            const maxWidth = 1920; // You can adjust this value
            const maxHeight = 1080; // You can adjust this value

            // Calculate new dimensions to fit within maxWidth and maxHeight while preserving aspect ratio
            let newWidth = imgElement.width;
            let newHeight = imgElement.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const aspectRatio = newWidth / newHeight;

              if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              }

              if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with new dimensions
            ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

            canvas.toBlob((blob) => {
              const file = new File([blob], 'image.jpg', {
                type: 'image/jpeg', // Change the type according to your original image
                lastModified: Date.now()
              });

              setImage(file);
              setImgFile(file)
              console.log("Converted file size:", file.size);
              console.log("Converted file ", file);
              setShowModal(true)
              // Now you have the file with the size below 4.5 MB
              // You can use this file for your further processing
            }, 'image/jpeg', 0.9); // Adjust JPEG quality as needed (0.7 is just an example)
          };
        };
        setImgRef(e.target);
      } catch (error) {
        console.error("error", error);
      } finally {
      }
    }
  };


  const handleDeleteClick = () => {
    setShowPopover(false);
    setShowDeletePopover(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;

    const croppedCanvas = cropper.getCroppedCanvas();

    // Convert canvas to blob
    croppedCanvas.toBlob(blob => {
      // Log the blob object
      console.log('blob', blob);
      setImgFile(blob)
      // Generate a URL for the blob
      const blobUrl = URL.createObjectURL(blob);
      setCroppedImage(blobUrl);

      // Log the blob URL
      console.log('blob URL', blobUrl);
    });
  };


  const handleConfirmDelete = () => {
    onDelete();
    setShowDeletePopover(false);
    setShowPopover(true);
  };
  const handleCloseDeletePopover = () => {
    setShowDeletePopover(false);
    setShowPopover(true);
  };

  const handleSaveChanges = () => {
    setShowModal(false)
    onSaveCroppedImage(croppedImage != null ? croppedImage : imageUrl, imgFile);
  };


  return (
    <div>
      <Card className="imagecard_card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <OverlayTrigger
          show={showPopover}
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            showDeletePopover ? (
              <Popover
                placement="top"
                show={showDeletePopover}
                onHide={handleCloseDeletePopover}
              >
                <div className="popover-content">
                  <span
                    style={{
                      color: "white",
                      fontSize: 15,
                      paddingRight: 10,
                      paddingLeft: 10,
                    }}
                  >
                    Loschen?
                  </span>
                  <Button
                    variant="danger"
                    className="m-1"
                    onClick={handleConfirmDelete}
                  >
                    <img src={require('../../assets/tick-white.png')} style={{ width: 15, height: 15 }} alt="EditIcon"></img>
                  </Button>
                  <Button className="m-1" onClick={handleCloseDeletePopover}>
                    <img src={require('../../assets/close-white.png')} style={{ width: 15, height: 15 }} alt="CloseIcon"></img>
                  </Button>
                </div>
              </Popover>
            ) : (
              <Popover id="popover">
                <div className="popover-content">
                  <Button className="m-1" onClick={handleEditClick}>
                    <img src={require('../../assets/edit_white.png')} style={{ width: 15, height: 15 }} alt="EditIcon"></img>
                  </Button>
                  <Button
                    className="m-1"
                    onClick={() => {
                      onEditText();
                    }}
                  >
                    <img src={require('../../assets/multiline-text.png')} style={{ width: 20, height: 20 }} alt="EditText"></img>
                  </Button>
                  <Button className="m-1" onClick={handleDeleteClick}>
                    <img src={require('../../assets/delete.png')} style={{ width: 20, height: 20 }} alt="DeleteIcon"></img>
                  </Button>
                </div>
              </Popover>
            )
          }
        >
          <Card.Img
            onLoad={(e) => onLoadImage(e)}
            className="uploaded-image1"
            src={updatedImage}
          />
        </OverlayTrigger>
      </Card>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        style={{ backgroundColor: "rgba(255,255,255,0.3)" }}
      >
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ height: "30vh", width: "60vh" }}>
            <Cropper
              ref={cropperRef}
              src={image}
              style={{ height: "100%", width: '100%' }}
              guides={true}
              scale={scale}
              viewMode={3}
              cropend={onCrop}
            />
          </div>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center">
          <Button className="next-btn" onClick={handleSaveChanges}>
            Übernehmen!
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImageCard;
