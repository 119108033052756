import React, { useState, useRef, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../../app.css'
import '../Ads/Ads.css';

import { newtaburl, Strings, URLS } from '../../utilities/constants';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { modules, formats } from '../../utilities/constants'
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import AlertComponent from "../../Components/AlertComponent";

const AdsVehicleDetails = ({ onBackPress, onNextPress, tabchanged, addId, onClick }) => {
  const navigate = useNavigate();

  const { userData, token, addDetails, setAddDetails, tab } = useContext(DataContext);
  const [descriptiopn, setDescription] = useState('');
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false);
  const [showalert, setShowAlert] = useState(false)
  const [detailsTabOpened, setDetailsTabOpend] = useState(false);

  const descriptiopnRef = useRef(descriptiopn);

  useEffect(() => {
    setDetailsTabOpend(true)
  }, [detailsTabOpened]);

  useEffect(() => {
    if (detailsTabOpened !== true) {
      if (addId !== undefined && addId !== '') {
        callAddDetailApi(addId, '')
      } else if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
        callAddDetailApi(addDetails.vehicle_ad_overview_data.id, 'details')
      }
    }

    return () => {
      console.log('tabdetailsvehicledetails', tab)
      if (tab !== 'details' && tab !== '') {
        setDetailsTabOpend(false)
        handleNextClick('tabchange')
      }
    };
  }, [tab]);


  useEffect(() => {
    descriptiopnRef.current = descriptiopn;
  }, [descriptiopn]);

  const formatAddName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleDescChange = (content) => {
    console.log('content', content)
    setDescription(content === '<p><br></p>' ? '' : content)
  }



  const handleBackClick = () => {
    if (!isEmptyObject(addDetails)) {
      callAddOverviewUpdateApi('back')
    } else {
      onBackPress()
    }
  }

  const handleNextClick = (btnAction) => {
    if (!isEmptyObject(addDetails)) {
      callAddOverviewUpdateApi(btnAction)
    }
  }

  const openNewTab = () => {
    // Check if running in development or production
    const formattedAddName = formatAddName(addDetails.vehicle_ad_overview_data.vehicle_name);
    // const baseUrl =
    //   process.env.NODE_ENV === "development"
    //     ? URLS.APP_LOCAL_URL
    //     : URLS.APP_STAGIN_URL;
    const baseUrl = newtaburl
    const componentPage = `ad/${addDetails.vehicle_ad_overview_data.id}`;
    const url = baseUrl + componentPage;
    window.open(url, "_blank");
  };

  const callAddDetailApi = async () => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addDetails.vehicle_ad_overview_data.id}`;
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          setAddDetails(response.data)
          setDescription(response.data.vehicle_ad_overview_data.vehicle_detail)
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }

  const callAddOverviewUpdateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHILCE_ADD_OVERVIEW_UPDATE}${addDetails.vehicle_ad_overview_data.id}/`;
    const form = new FormData();
    form.append('user', userData ? userData.id : '')
    form.append('vehicle_detail', descriptiopnRef.current == '<p><br></p>' ? '' : descriptiopnRef.current)
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";

    await axios.patch(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {

          if (btnAction == 'next') {
            onNextPress()
          } else if (btnAction == 'preview') {
            openNewTab()
          } else if (btnAction == 'back') {
            onBackPress()
          } else if (btnAction == 'tabchange') {
            onClick()
          }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });

  }


  return (
    <>
      <div>
        {loading && (
          <div className="loader">
            <ClipLoader
              color='orange'
              loading={loading}

              size={50}
            />
          </div>
        )}
        <Row>
          <Col md={9}>
            <ReactQuill
              theme="snow"
              modules={modules}
              className='quill_vehicle'
              formats={formats}
              placeholder="Fahrzeug Details ...."
              onChange={handleDescChange}
              value={descriptiopn}
            />
            {error && (
              <div style={{ display: 'flex', marginBottom: 20, marginTop: -10 }}>
                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} />
                <div className="error-message">{error}</div>
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <Row style={{ display: 'inline-flex' }}>
                <Col>
                  <button className="add_back_btn" style={{ minWidth: 120, marginBottom: 10 }} onClick={handleBackClick}>{Strings.back}</button>
                </Col>
                <Col>
                  <button className="next-btn" style={{ minWidth: 120, marginBottom: 10 }} onClick={() => { handleNextClick('next') }}>{Strings.next}</button>
                </Col>
                <Col>
                  <button className="add_preview_btn" style={{ minWidth: 120, marginBottom: 10 }} onClick={() => { handleNextClick('preview') }} >{Strings.preview}<img src={require('../../assets/desktop.png')} style={{ width: 15, height: 15, marginLeft: 5 }} /></button>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={3} />
        </Row>
        {showalert &&
          <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
        }
      </div>
    </>
  )
}

export default AdsVehicleDetails