import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import { DataProvider } from "./utilities/DataContext";
import { AnalyticsProvider, useAnalytics } from './utilities/AnalyticsContext';
import Cookies from 'js-cookie';
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';

const ConsentWrapper = ({ children }) => {
    const { updateConsent } = useAnalytics();
    const [consentGranted, setConsentGranted] = useState(false);

    useEffect(() => {
        getCookieConsentLevel();

        const acceptButton = document.querySelector('.cc-nb-okagree');
        if (acceptButton) {
            acceptButton.addEventListener('click', getCookieConsentLevel);
        }

        return () => {
            if (acceptButton) {
                acceptButton.removeEventListener('click', getCookieConsentLevel);
            }
        };
    }, []);

    const getCookieConsentLevel = () => {
        const level = Cookies.get('cookie_consent_level');
        if (typeof level === 'string') {
            try {
                const levelObject = JSON.parse(level);
                setConsentGranted(levelObject.tracking || false);
                updateConsent(levelObject.tracking || false);
            } catch (error) {
                console.error("Error parsing cookie:", error);
                setConsentGranted(false);
            }
        }
    };

    return children;
};

// Render App
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    <DataProvider>
        <Router>
            <AnalyticsProvider>
                <ConsentWrapper>
                    <App />
                </ConsentWrapper>
            </AnalyticsProvider>
        </Router>
    </DataProvider>
);

