import React, { useState, useRef, useEffect, useContext } from "react";

import { Row, Col } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import "../../app.css";
import "../PrivateUserRegistration/PrivateUserRegistration.css"
import TopLabelInput from "../../Components/TpLabelInput/TopLabelInput";
import { DataContext } from "../../utilities/DataContext";
import { Strings, URLS, usePageTracking } from "../../utilities/constants";
import axios from "axios";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import "quill/dist/quill.snow.css";
import AlertComponent from "../../Components/AlertComponent";
import heic2any from 'heic2any';
import SuccessAlert from "../../Components/SuccessAlert";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import ReactGA from 'react-ga4';
import { useAnalytics } from "../../utilities/AnalyticsContext";


const Profile = () => {
    const { markTitleUpdated } = useAnalytics()
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [error, setError] = useState('')
    const [showalert, setShowAlert] = useState(false)
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [logoRef, setLogoRef] = useState(null);
    const [countryCode, setCountryCode] = useState("DE");
    const [successAlert, setSuccessAlert] = useState(false)
    const [successMessage, setSuccessMsg] = useState('')
    const {
        userData,
        setUserData,
        token,
        setToken,
        setCompanyInfo,
        setIsLoggedIn,
        setUserType,
        setTab,
        setNoOfBedErr,
        setMessageCount,
        setChatData,
        setBusinessForm1data,
        setBusinessForm2data,
        setAddDetails, setPageTitleAvailable
    } = useContext(DataContext);
    const [form1Data, setForm1Data] = useState({
        firstName: "",
        lastName: "",
        street: "",
        streetNumber: "",
        zipCode: "",
        city: "",
        country: "",
        telephone: "",
        email: "",
    });

    const [form1Errors, setForm1Errors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        logo: ''
    });

    const pageTitle = 'Nutzerprofil anpassen'
    const previousTitleRef = useRef();

    useEffect(() => {
        document.title = 'Nutzerprofil anpassen'
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);


    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);

    // useEffect(() => {
    //     document.title = 'Nutzerprofil anpassen'
    //     if (window.trackPageTitleChange) {
    //         window.trackPageTitleChange('Nutzerprofil anpassen');
    //     }
    // }, []);

    const gotoHome = () => {
        setShowAlert(false)
        navigate('/home')
    }

    useEffect(() => {
        callUserProfileApi()
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        handleFile(file);
    };

    const handleFile = async (file) => {
        setLoading(true)
        let errors = {};
        if (file) {
            if (file.size >= 4.5 * 1024 * 1024) { // Check if file size is greater than 5MB
                errors.logo = Strings.image_size_error
                setLoading(false)
            } else {
                if (file.name.length > 100) {
                    errors.logo = Strings.image_filename_error
                    setLoading(false)
                } else {
                    if (file && file.type === 'image/heic') {
                        try {
                            const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
                            const url = URL.createObjectURL(convertedBlob);
                            setSelectedFile(convertedBlob);
                            setLoading(false)
                        } catch (error) {
                            console.error('Error converting HEIC file:', error);
                            setSelectedFile(file);
                            setLoading(false)
                        }
                    } else {
                        setLoading(false)
                        setSelectedFile(file);
                    }
                }
            }
            setForm1Errors(errors)
        }
    };


    const handleLogoImageLoaded = async (e) => {
        console.log("logoRef", logoRef);
        if (logoRef === null) {
            let img = e.target.src;
            console.log("img", img);
            try {
                const response = await fetch(img);
                const blob = await response.blob();
                const imgUrl = URL.createObjectURL(blob);
                img = imgUrl;
                console.log("logourl", imgUrl);
                console.log("logoblob", blob);
                setSelectedFile(blob);
                setLogoRef(e.target)
            } catch (error) {
                console.error("error", error);
            } finally {
                console.log("Fetch operation complete");
            }
        }
    };

    async function convertBlobUrlToFile(blobUrl) {
        try {
            // Fetch the blob data from the URL
            const response = await fetch(blobUrl);
            const blobData = await response.blob();
            const fileExtension = getFileExtensionFromMimeType(blobData.type);
            // Generate a unique file name with the determined extension
            const randomNumbers = Math.floor(Math.random() * 1000);
            const fileName = `file_from_blob_${randomNumbers}.${fileExtension}`;
            const file = new File([blobData], fileName, { type: blobData.type });
            return file;
        } catch (error) {
            console.error("Error converting Blob URL to File:", error);
            return null;
        }
    }

    function getFileExtensionFromMimeType(mimeType) {
        const mimeTypeMap = {
            "image/jpeg": "jpg",
            "image/png": "png",
            "image/gif": "gif",
            "image/webp": "webp",
            // Add more MIME types and their corresponding extensions as needed
        };

        // Get the corresponding file extension for the given MIME type
        return mimeTypeMap[mimeType] || "";
    }

    function isBlobURL(url) {
        const blobUrlRegex = /^blob:/i;
        return blobUrlRegex.test(url);
    }

    function createFileFromBlob(blob) {
        console.log('blob', blob)
        const fileName = generateRandomFileName();
        return new File([blob], fileName, { type: blob.type });
    }

    function generateRandomFileName() {
        const randomNumber = Math.floor(10 + Math.random() * 90); // Generates a random number between 10 and 99
        return `image_${randomNumber}.png`;
    }


    const handleInputChange = (label, value) => {
        console.log("label", label);
        if (label === "telephone") {
            console.log("value", value);
            setCountryCode(value.short);
            if (value.phone === '') {
                setForm1Data((prevFormData) => {
                    const updatedFormData = {
                        ...prevFormData,
                        [label]: "",
                    };
                    console.log("Updated form data1:", updatedFormData);
                    return updatedFormData;
                });
            } else {
                setForm1Data((prevFormData) => {
                    const updatedFormData = {
                        ...prevFormData,
                        [label]: `+${value.code} ${value.phone}`,
                    };
                    console.log("Updated form data2:", updatedFormData);
                    return updatedFormData;
                });
            }

        } else {
            setForm1Data((prevFormData) => {
                const updatedFormData = {
                    ...prevFormData,
                    [label]: value,
                };
                console.log("Updated form data:", updatedFormData);
                return updatedFormData;
            });
        }
    };

    const validateForm1Data = () => {
        const errors = {};
        if (form1Data.firstName === undefined || form1Data.firstName === "") {
            errors.firstName = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }
        if (form1Data.lastName === undefined || form1Data.lastName === "") {
            errors.lastName = "Bitte füllen Sie dieses Pflichtfeld aus.";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (form1Data.email === undefined || form1Data.email === "") {
            errors.email = 'Bitte füllen Sie dieses Pflichtfeld aus.'
        } else {
            if (!emailRegex.test(form1Data.email)) {
                errors.email = Strings.invalid_email
            }
        }

        // if (form1Data.password === undefined || form1Data.password === "") {
        //     errors.password = "Bitte füllen Sie dieses Pflichtfeld aus.";
        // }
        return errors;
    };

    const handleNextClick = () => {
        const errors = validateForm1Data();
        setForm1Errors(errors);
        if (Object.keys(errors).length === 0) {
            callUpateProfileApi()
        }
    };


    const callUserProfileApi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.PRIVATE_USER_PROFILE}${userData.id}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.get(url, { headers: { Authorization: token } }).then((response) => {
            setLoading(false)
            if (response?.data.status === 1) {
                const responsedata = response?.data.private_user_profile_information
                const data = responsedata.user;
                setUserData(data);
                console.log("response", data);
                const country_code = responsedata.country_code !== null ? responsedata.country_code : "DE";
                setCountryCode(country_code);
                setForm1Data({
                    firstName: data.first_name !== null ? data.first_name : "",
                    lastName: data.last_name !== null ? data.last_name : "",
                    street: responsedata.street !== null ? responsedata.street : "",
                    streetNumber: responsedata.street_number !== null ? responsedata.street_number : "",
                    zipCode: responsedata.zip_code !== null ? responsedata.zip_code : "",
                    city: responsedata.city !== null ? responsedata.city : "",
                    country: responsedata.country !== null ? responsedata.country : "",
                    telephone: responsedata.user_phonenumber !== null ? responsedata.user_phonenumber : "",
                    email: data.email !== null ? data.email : "",
                });

                setSelectedFile(responsedata.profile_image !== null ? responsedata.profile_image : null);
            } else {
                setLoading(false)
                setShowAlert(true)
                setError(response.data.message)
                if (error.response.data.message === 'Token fehlt!' || error.response.data.message === 'Der Token ist abgelaufen. Bitte melden Sie sich erneut an.' || error.response.data.message === 'Ungültiges Token. Bitte melden Sie sich erneut an.' || error.response.data.message === 'Benutzer nicht gefunden.' || error.response.data.message === 'Not found.') {
                    setIsLoggedIn(false)
                    setUserData({})
                    setCompanyInfo({})
                    setUserType('')
                    setTab('')
                    setNoOfBedErr('')
                    setMessageCount('')
                    setToken('')
                    setChatData([])
                    setBusinessForm1data({})
                    setBusinessForm2data({})
                    setAddDetails({})
                }
            }
        })
            .catch(function (error) {
                setLoading(false)
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    const callUpateProfileApi = async () => {
        const url = `${URLS.BASE_URL}${URLS.UPDATE_USER_PROFILE}${userData.id}/`;
        const form = new FormData();
        console.log("selectedFile", selectedFile);
        let logofile = null
        if (selectedFile != null) {
            if (isBlobURL(selectedFile)) {
                convertBlobUrlToFile(selectedFile).then((file) => {
                    if (file) {
                        logofile = file;
                        console.log("logofile", logofile);
                    } else {
                        console.log("Failed to convert Blob URL to File.");
                    }
                });
            } else if (selectedFile instanceof File) {
                let url = URL.createObjectURL(selectedFile);
                console.log("CONVERTTED URL LOGO", url);
                logofile = selectedFile;
            } else if (selectedFile instanceof Blob) {
                let blobfile = createFileFromBlob(selectedFile)
                let url = URL.createObjectURL(blobfile);
                console.log("CONVERTTED URL LOGO", url);
                logofile = blobfile;
            }
        }
        form.append("profile_image", logofile);
        form.append("first_name", form1Data.firstName ? form1Data.firstName : '');
        form.append("last_name", form1Data.lastName ? form1Data.lastName : '');
        form.append("email", form1Data.email ? form1Data.email : '');
        form.append("street", form1Data.street ? form1Data.street : '');
        form.append("street_number", form1Data.streetNumber ? form1Data.streetNumber : '');
        form.append("zip_code", form1Data.zipCode ? form1Data.zipCode : '');
        form.append("city", form1Data.city ? form1Data.city : '');
        form.append("country", form1Data.country ? form1Data.country : '');
        form.append("user_phonenumber", form1Data.telephone ? form1Data.telephone : '');
        form.append("country_code", countryCode != null ? countryCode : '');
        const formDataObject = {};
        form.forEach((value, key) => {
            formDataObject[key] = value;
        });

        console.log("formDataObject", formDataObject);
        setLoading(true)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        await axios
            .put(url, form, { headers: { Authorization: token } })
            .then((response) => {
                console.log("response", response);
                setLoading(false);
                if (response?.data.status === 1) {
                    setSuccessAlert(true)
                    setSuccessMsg(response?.data.message)
                    setToken(response?.data.token)
                    let userdata = response?.data.private_user_profile_information.user
                    setUserData(userdata)
                    setCompanyInfo(response?.data.private_user_profile_information)

                } else {
                    console.log("response", response);
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false);
                console.log("error", error);
                if (error.response) {
                    setShowAlert(true)
                    setError(error.response.data.message)
                }
            });
    }

    return (
        <>
            <Header />
            {loading && (
                <div className="loader">
                    <ClipLoader color='orange' loading={loading} size={50} />
                </div>
            )}
            <div style={{ position: 'absolute', marginTop: 75, height: '90%', right: 0, left: 0, display: 'flex', justifyContent: 'center' }}>
                <Row style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Col xxl={8} xl={8} lg={10} md={11} xs={10} style={{ paddingTop: '8vmin', paddingLeft: '16vmin' }}>
                        <div>
                            <h1 className="headertext1">
                                {`Hallo ${form1Data.firstName}`}
                            </h1>
                            <h1 className="reg_subheadertext">
                                Hier kannst du dein Nutzerprofil anpassen
                            </h1>
                        </div>
                        <div
                            className="dashed-view"
                            style={{ marginBottom: 20, marginTop: 20 }}
                        >
                            <div htmlFor="fileInput" className="upload-label">
                                {(selectedFile !== null) && (
                                    <img
                                        src={(selectedFile instanceof File ||
                                            selectedFile instanceof Blob) ? URL.createObjectURL(selectedFile) : selectedFile}
                                        alt="Upload"
                                        onLoad={handleLogoImageLoaded}
                                        className="logo-image"
                                    />
                                )}
                                <div>
                                    {!selectedFile ? (
                                        <div className="upload-placeholder">
                                            <img
                                                alt="selectedFile"
                                                style={{ width: 15, height: 15 }}
                                                src={require("../../assets/placeholder.png")}
                                            ></img>
                                        </div>
                                    ) : null}

                                    <input
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            cursor: "pointer",
                                        }}
                                        ref={fileInputRef}
                                        type="file"
                                        accept="uploadedimage/*"
                                        onChange={handleFileChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {form1Errors.logo && (
                            <div style={{ display: "flex", marginTop: 5 }}>
                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} />
                                <div className="error-message">
                                    {form1Errors.logo}
                                </div>
                            </div>
                        )}
                        <Row style={{ marginTop: '5vmin' }}>

                            <Col xxl={6} xl={6} lg={6} md={6} xs={12}>

                                <h1 className="reg_subheadertext mt2 mb2">
                                    Name
                                </h1>
                                <Row style={{ marginLeft: 0 }}>
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Vorname*"
                                            value={form1Data.firstName ? form1Data.firstName : ''}
                                            onChange={(e) =>
                                                handleInputChange("firstName", e.target.value)
                                            }
                                        />

                                    </Col>
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Nachname*"
                                            value={form1Data.lastName ? form1Data.lastName : ''}
                                            onChange={(e) =>
                                                handleInputChange("lastName", e.target.value)
                                            }
                                        />
                                    </Col>
                                    {(form1Errors.firstName || form1Errors.lastName) && (
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: 10,
                                                marginTop: -10,
                                            }}
                                        >
                                            <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} />
                                            <span className="error-message">{form1Errors.firstName !== undefined ? form1Errors.firstName : form1Errors.lastName}</span>
                                        </div>
                                    )}
                                </Row>

                            </Col>
                        </Row>
                        <Row>
                            <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                                <h1 className="reg_subheadertext mt2 mb2">
                                    Adresse
                                </h1>
                                <Row style={{ marginLeft: 0 }}>
                                    <Col xxl={6} xl={7} lg={6} md={6} xs={8} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Straße"
                                            value={form1Data.street ? form1Data.street : ''}
                                            onChange={(e) =>
                                                handleInputChange("street", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col xxl={4} xl={3} lg={4} md={4} xs={4} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Nr."
                                            value={form1Data.streetNumber ? form1Data.streetNumber : ''}
                                            onChange={(e) =>
                                                handleInputChange("streetNumber", e.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: 0 }}>
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Postleitzahl"
                                            value={form1Data.zipCode ? form1Data.zipCode : ''}
                                            onChange={(e) =>
                                                handleInputChange("zipCode", e.target.value)
                                            }
                                        />
                                    </Col>
                                    <Col xxl={5} xl={5} lg={5} md={5} xs={6} className="reg-col-input">
                                        <TopLabelInput
                                            type={"text"}
                                            label="Stadt"
                                            value={form1Data.city ? form1Data.city : ''}
                                            onChange={(e) =>
                                                handleInputChange("city", e.target.value)
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: 0 }}>
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Land"
                                            value={form1Data.country ? form1Data.country : ''}
                                            onChange={(e) =>
                                                handleInputChange("country", e.target.value)
                                            }
                                        />
                                    </Col>

                                </Row>

                            </Col>
                            <Col xxl={6} xl={6} lg={6} md={6} xs={12}>
                                <h1 className="reg_subheadertext mt2 mb2">
                                    Kontaktdaten
                                </h1>
                                <Row style={{ marginLeft: 0 }} >
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"text"}
                                            label="Email*"
                                            value={form1Data.email}
                                            onChange={(e) =>
                                                handleInputChange("email", e.target.value)
                                            }
                                        />
                                        {(form1Errors.email) && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    marginBottom: 10,
                                                    marginTop: -10,
                                                }}
                                            >
                                                <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} />
                                                <span className="error-message">{form1Errors.email}</span>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row style={{ marginLeft: 0 }}>
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"tel"}
                                            label="Telefonnummer"
                                            short={countryCode}
                                            value={form1Data.telephone ? form1Data.telephone : ''}
                                            onChange={(value) => {
                                                handleInputChange("telephone", {
                                                    code: value.code,
                                                    phone: value.phone,
                                                    short: value.short,
                                                });
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {/* <Row >
                                    <Col xxl={10} xl={10} lg={10} md={10} xs={12} className="reg-col-input" >
                                        <TopLabelInput
                                            type={"password"}
                                            label="Passwort*"
                                            value={form1Data.password}
                                            onChange={(e) =>
                                                handleInputChange("password", e.target.value)
                                            }
                                        />
                                    </Col>
                                    {(form1Errors.password) && (
                                        <div
                                            style={{
                                                display: "flex",
                                                marginBottom: 10,
                                                marginTop: -10,
                                            }}
                                        >
                                            <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}/>
                                            <span className="error-message">{form1Errors.password}</span>
                                        </div>
                                    )}
                                </Row> */}
                            </Col>
                        </Row>
                        <span className="note">Mit einem * markierte Felder stellen Pflichtangaben dar</span>
                        <div style={{ display: "flex", marginTop: "2vmin", marginBottom: '2vmin' }}>
                            <button
                                className="next-btn1"
                                onClick={handleNextClick}
                            >
                                Speichern
                            </button>
                        </div>
                    </Col>
                </Row >
                {showalert &&
                    <AlertComponent message={error} onClose={() => { gotoHome() }}></AlertComponent>
                }
                {successAlert &&
                    <SuccessAlert message={successMessage} onClose={() => { setSuccessAlert(false) }}></SuccessAlert>
                }
            </div>
        </>)
}


export default Profile