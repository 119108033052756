import React from 'react';
import '../Messaging/Messaging.css'


const LeftPanel = ({ loginUserData, profileInfo, contacts, handleContactClick, selectedContactId, handelSearch, handleProfileNameClick, handleAdClick }) => {
    return (
        <>
            <div className="search-box">
                <div className='search-box-div '>
                    <img src={require('../../assets/search_black.png')} style={{ width: 15, height: 15, marginRight: 10 }}></img>
                    <input type="text" placeholder="Person suchen" className='search-input-text'
                        onChange={(e) => { handelSearch(e.target.value) }}
                    />
                </div>

            </div>
            <div className="contacts-list">
                {contacts.map(contact => (
                    <div key={contact.id} onClick={() => handleContactClick(contact)} className={`contact_div ${contact.id === selectedContactId ? 'selected' : ''}`} style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, marginLeft: 10, marginRight: 10, alignItems: 'center' }}>
                        <div style={{ alignContent: 'center', display: 'flex', marginRight: 10, borderRadius: '50%', width: 50, height: 50 }}>
                            <img src={contact.logo !== null ? contact.logo : require('../../assets/add_placeholder.jpeg')} style={{ objectFit: 'cover', borderRadius: '50%', height: 50, width: 50 }} />
                        </div>
                        <div className={`contact-item`}>
                            <span
                                className="contact_name"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (contact.company_information_id) {
                                        handleProfileNameClick(contact);
                                    }
                                }}
                                onMouseEnter={(e) => {
                                    if (contact.company_information_id) {
                                        e.target.style.textDecoration = 'underline';
                                        e.target.style.color = 'blue'
                                        e.target.style.cursor = 'pointer'
                                    }
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.textDecoration = 'none';
                                    e.target.style.color = 'black'
                                    e.target.style.cursor = 'default'
                                }}
                            >
                                {contact.profile_name}
                            </span>

                            {/* <span className='contact_ad'>{contact.vehicle_name}</span> */}
                            <span
                                className="contact_ad"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleAdClick(contact);

                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.textDecoration = 'underline';
                                    e.target.style.color = 'blue'
                                    e.target.style.cursor = 'pointer'
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.textDecoration = 'none';
                                    e.target.style.color = 'black'
                                    e.target.style.cursor = 'default'
                                }}
                            >
                                {contact.vehicle_name}
                            </span>
                        </div>
                        <div>
                            {contact.unread_count > 0 && (
                                <span className='message-count'>
                                    {contact.unread_count > 9 ? '9+' : contact.unread_count}
                                </span>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className="leftpannel_footer">
                <img src={loginUserData.user_type == "business-user" ? profileInfo.company_logo !== null ? profileInfo.company_logo : require('../../assets/add_placeholder.jpeg') : profileInfo.profile_image !== null ? profileInfo.profile_image : require('../../assets/add_placeholder.jpeg')} alt="Logo" className='user_image' />
                <span className='left_text'>{loginUserData.user_type == "business-user" ? profileInfo.company_name : `${loginUserData.first_name} ${loginUserData.last_name}`}</span>
            </div>
        </>
    );
}

export default LeftPanel;
