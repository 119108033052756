import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import '../AdminInstaList/AdminInstaList.css'
import { Col, Row, Modal, Button } from 'react-bootstrap';
import '../../../app.css';
import AdminHeader from '../AdminHeader';
import { newtaburl, URLS, usePageTracking } from '../../../utilities/constants';
import axios from 'axios';
import sanitizeHtml from 'sanitize-html';
import ReactQuill from 'react-quill';
import { DataContext } from '../../../utilities/DataContext';
import 'react-quill/dist/quill.snow.css';
import { ClipLoader } from "react-spinners";
import SuccessAlert from '../../../Components/SuccessAlert';
import AlertComponent from "../../../Components/AlertComponent";
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { useAnalytics } from '../../../utilities/AnalyticsContext';



const AdminInstaList = () => {
    const { markTitleUpdated } = useAnalytics()
    const { setPageTitleAvailable, setBusinessForm1data, setBusinessForm2data, token, setIsLoggedIn, setToken, setUserData, setCompanyInfo, setUserType, userData, setChatData, setMessageCount, setTab, setNoOfBedErr, setAddDetails } = useContext(DataContext);
    const [instaList, setInstaList] = useState([])
    const [editingPostId, setEditingPostId] = useState(null);
    const [editedText, setEditedText] = useState('');
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [successAlert, setShowSuccessAlert] = useState(false);
    const [successMessage, setSuccessMsg] = useState('')
    const [responseErr, setResponseErr] = useState('')

    const previousTitleRef = useRef();
    const pageTitle = 'Insta List'

    useEffect(() => {
        document.title = pageTitle
        // markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = ((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    });

    useEffect(() => {
        getInstaPosts()
    }, [])

    // const debouncedTrackPageTitleChange = debounce((title) => {
    //     if (previousTitleRef.current !== title && window.trackPageTitleChange) {
    //         window.trackPageTitleChange(title);
    //     }
    //     // Update the previous title reference
    //     previousTitleRef.current = title;
    // }, 500);


    const createMarkup = (htmlContent) => {
        return { __html: sanitizeHtml(htmlContent) };
    };


    const handleEditClick = (postId, currentText) => {
        setEditingPostId(postId);
        setEditedText(currentText);
        setShowModal(true); // Open the modal
    };

    const gotoLogin = () => {
        setResponseErr('')
        setUserData({})
        setCompanyInfo({})
        setUserType('')
        setTab('')
        setNoOfBedErr('')
        setMessageCount('')
        setToken('')
        setChatData([])
        setBusinessForm1data({})
        setBusinessForm2data({})
        setIsLoggedIn(false)
        setAddDetails({})
    }


    const handleIgnoreClick = (postId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.INSTA_POST_UPDATE}${postId}/`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        const form = new FormData();
        form.append('publish_status', 'ignored')
        axios.patch(url, form, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response insta update', response);
                if (response?.data.status === 1) {
                    getInstaPosts()
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error)
            });
    };


    const handlePublishClick = (postId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.INSTA_PUBLISH}${postId}/`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                setLoading(false)
                console.log('response insta update', response);
                if (response?.data.status === 1) {
                    setShowSuccessAlert(true)
                    setSuccessMsg(response?.data.message)
                    getInstaPosts()
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error)
            });
    };

    const handleCancelClick = () => {
        setEditingPostId(null);
    }

    const handleSaveClick = (postId) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.INSTA_POST_UPDATE}${postId}/`;
        const form = new FormData();
        form.append('caption', editedText)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        axios.patch(url, form, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log('response insta update', response);
                setLoading(false)
                if (response?.data.status === 1) {
                    setEditingPostId(null);
                    setInstaList(instaList.map(post => post.id === postId ? { ...post, caption: response?.data.data.caption } : post));
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error)
            });
    };


    const handleAdClick = (ad) => {
        // navigate(`/ad/${contact.vehicle_ad_overview}`)
        const baseUrl = newtaburl
        const componentPage = `ad/${ad.vehicle_ad_overview}`;
        const url = baseUrl + componentPage;
        openNewTab(url)
    }

    const handleBusinessPageClick = (details) => {
        const baseUrl = newtaburl
        const formattedCompanyName = details.formatted_company_name
        const componentPage = `businesspage/${formattedCompanyName}`;
        const url = baseUrl + componentPage;
        openNewTab(url)
    }

    const openNewTab = (url) => {
        window.open(url, "_blank");
    };

    const getInstaPosts = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.INSTA_POSTS}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.get(url, { headers: { 'Authorization': token } })
            .then((response) => {
                console.log('response', response)
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response?.data.data
                    setInstaList(data)
                } else {
                    alert(response.data.message);
                }
            })
            .catch(function (error) {
                setLoading(false)
                if (error.response && error.response.data.message === 'Ungültiges Token. Bitte melden Sie sich erneut an.') {
                    setResponseErr(error.response.data.message);
                }
            });
    }

    return (
        <>
            <AdminHeader></AdminHeader>
            {loading && (
                <div className="loader">
                    <ClipLoader
                        color='orange'
                        loading={loading}
                        size={50}
                    />
                </div>
            )}
            <div style={{ position: 'absolute', top: 80, padding: 20 }}>
                <span className="headertext">Administraton InstaGrams</span>
                {/* <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Ad Name</th>
                        <th>Business Name</th>
                        <th>Image</th>
                        <th>Text</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {posts.map((post, index) => (
                        <tr key={post.id}>
                            <td>{index + 1}</td>
                            <td>{new Date(post.date).toLocaleDateString()}</td>
                            <td>
                                <a href={post.adLink} target="_blank" rel="noopener noreferrer">
                                    {post.adName}
                                </a>
                            </td>
                            <td>
                                <a href={post.businessLink} target="_blank" rel="noopener noreferrer">
                                    {post.businessName}
                                </a>
                            </td>
                            <td>
                                <img src={post.image} alt="Instagram Post" style={{ width: '100px' }} />
                            </td>
                            <td> {editingPostId === post.id ? (
                                <Form.Control
                                    as="textarea"
                                    value={editedText}
                                    onChange={(e) => setEditedText(e.target.value)}
                                />
                            ) : (
                                post.text
                            )}
                            </td>
                            <td>
                                {editingPostId === post.id ? (
                                    <Button variant="success" onClick={() => handleSaveClick(post.id)}>Save</Button>
                                ) : (
                                    <Button variant="primary" onClick={() => handleEditClick(post.id, post.text)}>Edit</Button>
                                )}
                                <Button variant="warning" onClick={() => handlePublishClick(post.id)}>Publish</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table> */}
                {instaList.map((post) => (
                    <div key={post.id} className="mb-4 p-3 border rounded mt-4" >
                        <Row className="mb-2">
                            <Col><span className='adheader'>Date:</span> <span className='addate'>{new Date(post.created_datetime).toLocaleDateString()}</span></Col>
                            <Col><span className='adheader'>Ad Name:</span>
                                <span
                                    className="adname"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAdClick(post);
                                    }}
                                >
                                    {post.vehicle_name}
                                </span>
                            </Col>
                            <Col><span className='adheader'>Business Name</span>
                                <span
                                    className="adname"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleBusinessPageClick(post);
                                    }}
                                >
                                    {post.company_name}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <div className="ml-3">

                                <div className="flex-grow-1 d-flex">
                                    <img src={post.instagram_hero_image_url} className='instaimg' alt="Instagram Post" style={{ width: '100%', maxWidth: '250px', marginTop: '10px', marginRight: 10 }} />

                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {editingPostId === post.id ? (
                                            <div className='paragraph-container'>
                                                <ReactQuill
                                                    value={editedText}
                                                    onChange={setEditedText}
                                                    style={{ marginLeft: 15, marginRight: 15, resize: 'none', height: '80%' }}
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                                <div className='paragraph-container' >
                                                    <div className='paragraph' dangerouslySetInnerHTML={createMarkup(post.caption)} />
                                                </div>
                                                <img
                                                    src={require('../../../assets/edit_gray.png')}
                                                    style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10, width: 20, height: 20 }}
                                                    onClick={() => handleEditClick(post.id, post.caption)}
                                                />
                                            </div>
                                        )}
                                        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
                                            <div className='paragraph-container' >
                                                <div className='paragraph' dangerouslySetInnerHTML={createMarkup(post.caption)} />
                                            </div>
                                            <img
                                                src={require('../../../assets/edit_gray.png')}
                                                style={{ cursor: 'pointer', marginLeft: 10, marginRight: 10, width: 20, height: 20 }}
                                                onClick={() => handleEditClick(post.id, post.caption)}
                                            />
                                        </div> */}
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', margin: 10 }}>
                                        {editingPostId === post.id ? (
                                            <div className="flex-grow-1 d-flex justify-content-center flex-direction-row" style={{ flexDirection: 'row' }}>
                                                <button className="ml-2 btnIgnore" style={{ marginRight: 5 }} onClick={() => handleCancelClick(post.id)}>Cancel</button>
                                                <button className="ml-2 btnsave" onClick={() => handleSaveClick(post.id)}>Save</button>
                                            </div>
                                        ) :
                                            <div className="flex-grow-1 d-flex justify-content-center flex-direction-row" style={{ flexDirection: 'row' }}>
                                                {post.publish_status === 'unpublished' && <button className=" ml-2 publishbtninsta" onClick={() => handlePublishClick(post.id)} >Publish</button>}
                                                {post.publish_status === 'unpublished' && <button className="btnIgnore" onClick={() => handleIgnoreClick(post.id)} >Ignore</button>}
                                            </div>}

                                    </div>
                                </div>

                            </div>
                        </Row>

                    </div>
                ))}
                {successAlert &&
                    <SuccessAlert message={successMessage} onClose={() => { setShowSuccessAlert(false) }}></SuccessAlert>
                }
            </div>
            {/* Modal for editing */}
            {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactQuill
                        value={editedText}
                        onChange={handleQuillChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btnIgnore" onClick={() => setShowModal(false)} >Close</button>
                    <button className="ml-2 btnsave" onClick={() => handleSaveClick(editingPostId)}>Save</button>
                </Modal.Footer>
            </Modal> */}
            {responseErr !== '' && <AlertComponent message={responseErr} onClose={() => { gotoLogin() }} />}
        </>
    )
}
export default AdminInstaList;

