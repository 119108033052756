import React, { useState, useEffect, useContext, useRef } from 'react';
import '../Finisher/Finisher.css';
import { Row, Col } from "react-bootstrap";
import Footer from '../../Components/Footer/Footer';
import Header from '../../Components/Header/Header';
import '../../app.css'
import { Strings, URLS, usePageTracking } from '../../utilities/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../utilities/DataContext';
import { ClipLoader } from 'react-spinners';
import AlertComponent from "../../Components/AlertComponent";
import { debounce } from 'lodash';
import ReactGA from 'react-ga4';
import { useAnalytics } from '../../utilities/AnalyticsContext';




const FinisherScreen = () => {
    const { markTitleUpdated } = useAnalytics()
    const { setBusinessForm1data, setBusinessForm2data, setPageTitleAvailable } = useContext(DataContext);
    const navigate = useNavigate();
    const cardContainerRef = useRef(null);
    const [showalert, setShowAlert] = useState(false)
    const [error, setError] = useState('')
    const [companiesData, setCompaniesData] = useState([])
    const [loading, setLoading] = useState(false);
    // const [selectedOptions, setSelectedOptions] = useState(['retailer', 'manufacturer', 'upgrader', 'rental company']);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isMobile, setIsMobile] = useState(false)
    const [pageNumber, setPageNumber] = useState(1);
    const [nextPageNumber, setNextPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [hasMore, setHasMore] = useState(false);

    const pageTitle = 'Alle Unternehmen auf campervanspot.com';
    const previousTitleRef = useRef();
    useEffect(() => {
        document.title = 'Alle Unternehmen auf campervanspot.com'
        markTitleUpdated(pageTitle)
    }, [])

    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);

    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);

    // useEffect(() => {
    //     document.title = 'Alle Unternehmen auf campervanspot.com';
    //     if (window.trackPageTitleChange) {
    //         window.trackPageTitleChange('Alle Unternehmen auf campervanspot.com');
    //     }
    // }, [])




    const calculatePageSize = (width, height) => {
        if (width < 600) {
            // Small devices like mobile
            return Math.floor((height / 100) * 0.8); // Adjust based on height
        } else if (width < 1024) {
            // Tablets
            return Math.floor((height / 100) * 1.2);
        } else {
            // Desktops
            return Math.floor((height / 100) * 1.5);
        }
    };



    useEffect(() => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [window.innerWidth]);

    // useEffect(() => {
    //     const updatePageSize = () => {
    //         const width = window.innerWidth;
    //         const height = window.innerHeight;
    //         const calculatedPageSize = calculatePageSize(width, height);
    //         console.log("calculatedPageSize", calculatedPageSize);
    //         setPageSize(calculatedPageSize);
    //         setPageNumber(1)
    //         setNextPageNumber(1)
    //     };

    //     // Set initial page size
    //     updatePageSize();

    //     // Update page size on window resize
    //     window.addEventListener('resize', updatePageSize);

    //     // Cleanup event listener on component unmount
    //     return () => {
    //         window.removeEventListener('resize', updatePageSize);
    //     };
    // }, []);

    useEffect(() => {
        // const width = window.innerWidth;
        // const height = window.innerHeight;
        // const calculatedPageSize = calculatePageSize(width, height);
        // console.log("calculatedPageSize", calculatedPageSize);
        // setPageSize(calculatedPageSize);
        getfilteredCompanies(selectedOptions, nextPageNumber, '', '')
        // document.title = 'Alle Unternehmen auf campervanspot.com'
    }, []);


    const loadMore = async () => {
        getfilteredCompanies(selectedOptions, nextPageNumber, pageSize, 'loadmore')
    };



    const handleOptionSelect = (option) => {
        console.log("selectedOptions", selectedOptions);
        const selectedIndex = selectedOptions?.indexOf(option);
        console.log("selectedIndex", selectedIndex);
        if (selectedIndex === -1 || selectedIndex === undefined) {
            // Option not selected, add it to the list
            const list = [...selectedOptions, option]
            setSelectedOptions(list);
            setPageNumber(1)
            setNextPageNumber(1)
            getfilteredCompanies(list, 1, pageSize, 'filter')
        } else {
            // Option already selected, remove it from the list
            const updatedOptions = [...selectedOptions];
            updatedOptions.splice(selectedIndex, 1);
            setSelectedOptions(updatedOptions);
            setPageNumber(1)
            setNextPageNumber(1)
            getfilteredCompanies(updatedOptions, 1, pageSize, 'filter')
        }
    };

    function translateOptions(options) {
        const translatedOptions = options.map(option => {
            switch (option) {
                case 'Hersteller':
                    return 'manufacturer';
                case 'Ausbauer':
                    return 'upgrader';
                case 'Händler':
                    return 'retailer';
                // Add more cases for additional options if needed
                default:
                    return option;
            }
        });
        return translatedOptions;
    }

    const getCompanies = async (page, size) => {
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.ALL_COMPANIES}?page=${page}&page_size=${size}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.get(url)
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response?.data.data
                    const filteredCompanies = data.filter(company => company.companybusinesspage !== null);
                    // setCompaniesData(filteredCompanies);
                    setCompaniesData(prevData => [...prevData, ...filteredCompanies]);
                    setNextPageNumber(response.data.next !== null ? response.data.next : page);
                    setHasMore(response.data.next !== null ? true : false);
                } else {
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                setShowAlert(true)
                setError(error.response ? error.response.data.message : '')
                console.log('error', error);
            });
    }

    const getfilteredCompanies = async (businessType, page, size, apitype) => {
        const string = businessType.length > 0 ? JSON.stringify(businessType) : ''
        setLoading(true)
        const url = `${URLS.BASE_URL}${URLS.FILTERD_COMPANIES}${string}`;
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        console.log('url', url)
        await axios.get(url)
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    const data = response?.data.data
                    // const filteredCompanies = data.filter(company => company.companybusinesspage !== null);
                    // setCompaniesData(filteredCompanies);
                    // setCompaniesData(prevData => [...prevData, ...filteredCompanies]);
                    if (apitype === 'loadmore') {
                        setCompaniesData(prevData => {
                            // Filter out companies with null companybusinesspage and avoid duplicates
                            const filteredCompanies = data
                                .filter(company => company.companybusinesspage !== null)
                                .filter(company => !prevData.some(prevCompany => prevCompany.id === company.id));

                            return [...prevData, ...filteredCompanies];
                        });
                    } else {
                        const filteredCompanies = data.filter(company => company.companybusinesspage !== null);
                        setCompaniesData(filteredCompanies)
                    }
                    // setNextPageNumber(response.data.next !== null ? response.data.next : page);
                    // setHasMore(response.data.next !== null ? true : false);
                } else {
                    setLoading(false)
                    setShowAlert(true)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                setShowAlert(true)
                setError(error.response ? error.response.data.message : '')
                console.log('error', error);
            });
    }

    const formatCompanyName = (name) => {
        return name
            .toLowerCase()                          // Convert to lowercase
            .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
            .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
    };



    const nextClicked = (company) => {
        setBusinessForm1data({})
        setBusinessForm2data({})
        const formattedCompanyName = formatCompanyName(company.company_name);
        navigate(`/businesspage/${formattedCompanyName}`, { state: { id: company.id } })
    }

    return (
        <>

            <Header />
            <div style={{ height: "100vh" }}>
                <div className='banner dnd_area-module-2' style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/finisher_bg.webp"})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', }} >
                    {/* <img src={require('../../assets/dashboard_bg.png')} style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, width: '100%', height: '90%' }}></img> */}
                    <div className='overlay' />
                    <div className='container'>
                        <div className='banner-content-wrapper'>
                            <div className='text-content'>
                                <h4 className='h4_textstyle'>{Strings.finisherpage_desc1}</h4>
                                <h1 className='h1_textstyle'>{Strings.finisherpage_desc2}</h1>
                            </div>
                        </div>
                        <div className="blurview">
                            <Row style={{ width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                {/* <Col xl={2}><h6 className='filter-text'>Unternehmensart:</h6></Col> */}
                                <Col xl={9}>

                                    <Row className="options-container">
                                        <Col><div style={{ display: 'flex', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}><span className='filter-text'>Unternehmensart:</span></div></Col>
                                        <Col
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            className={`option ${selectedOptions &&
                                                selectedOptions.length > 0 &&
                                                selectedOptions.includes("retailer")
                                                ? "selected"
                                                : ""
                                                }`}
                                            onClick={() => handleOptionSelect("retailer")}
                                        >
                                            Händler
                                        </Col>
                                        <Col
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            className={`option ${selectedOptions &&
                                                selectedOptions.length > 0 &&
                                                selectedOptions.includes("manufacturer")
                                                ? "selected"
                                                : ""
                                                }`}
                                            onClick={() => handleOptionSelect("manufacturer")}
                                        >
                                            Hersteller
                                        </Col>
                                        <Col
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            className={`option ${selectedOptions &&
                                                selectedOptions.length > 0 &&
                                                selectedOptions.includes("upgrader")
                                                ? "selected"
                                                : ""
                                                }`}
                                            onClick={() => handleOptionSelect("upgrader")}
                                        >
                                            Ausbauer
                                        </Col>
                                        <Col
                                            style={{ marginBottom: 5, marginTop: 5 }}
                                            className={`option ${selectedOptions &&
                                                selectedOptions.length > 0 &&
                                                selectedOptions.includes("rental company")
                                                ? "selected"
                                                : ""
                                                }`}
                                            onClick={() => handleOptionSelect("rental company")}
                                        >
                                            Vermieter
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div className="mapblurview">
                            <Row style={{ width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <Col xl={9}>
                                    <a href="https://www.google.com/maps/d/u/0/edit?mid=1sMjj6TBOauetqiWeu5OFKfk-9Obh4UM&usp=sharing" target="_blank" rel="noopener noreferrer" className="map_link">
                                        <span className='map_text'>Kartendarstellung:</span>
                                    </a>
                                    <a href="https://www.google.com/maps/d/u/0/edit?mid=1sMjj6TBOauetqiWeu5OFKfk-9Obh4UM&usp=sharing" target="_blank" rel="noopener noreferrer">
                                        <img src={require('../../assets/logo_google_maps.png')} style={{ width: 35, height: 35 }} alt="Google Maps" />
                                    </a>
                                </Col>
                            </Row>
                        </div>
                        {/* <a href="https://www.google.com/maps/d/u/0/edit?mid=1sMjj6TBOauetqiWeu5OFKfk-9Obh4UM&usp=sharing" target="_blank" rel="noopener noreferrer">
                            <div className="blurview">
                                <span className='map_text'>Kartendarstellung</span>
                                <img src={require('../../assets/logo_google_maps.png')} style={{ width: 35, height: 35 }} alt="Google Maps" />
                            </div>
                        </a> */}
                    </div>
                </div>
                <div className='cardcontainer'>
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ backgroundColor: 'red', borderRadius: 5, paddingLeft: 20, paddingRight: 20, paddingTop: 5, paddingBottom: 5, alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: 10, }}>
                            <span style={{ color: 'white' }}>{'Wir prüfen gerade Ihre Unternehmensseite und werde diese kurzfristig frei schalten'}</span>
                        </div>
                    </div> */}

                    <div className='heading'>
                        <span className='headingtext'>{Strings.finisher_haeding1}<strong> {Strings.finisher_haeding2}</strong> </span>
                        <div className='divider' />
                    </div>

                    <div className='section_cards' ref={cardContainerRef}>
                        {loading && (
                            <div className="loader">
                                <ClipLoader color='orange' loading={loading} size={50} />
                            </div>
                        )}
                        {companiesData.map(company => (
                            <div onClick={() => nextClicked(company)} className='imagecard' style={{ backgroundImage: (company.companybusinesspage != null && company.companybusinesspage.hero_image) ? `url(${company.companybusinesspage.hero_image})` : `url(${process.env.PUBLIC_URL + "/placeholder.webp"})` }}>
                                <Row className='content row-card'>
                                    <Col >
                                        {/* <div className='title'> */}
                                        <h4 className='title' style={{ marginRight: 10 }}>
                                            {company.company_name}
                                        </h4>
                                        {/* </div> */}
                                        {/* <div className='subtitle'> */}
                                        <h6 className='subtitle'> {`${company.city},
                                            ${company.country}`}</h6>
                                        {/* </div> */}
                                    </Col>
                                    <Col md={2} sm={2} xl={2} xs={2} lg={2} className="arrow-icon">
                                        <img src={require('../../assets/right-arrow.png')} style={{ width: 30, height: 30 }}></img>
                                    </Col>
                                </Row>
                            </div>

                        ))}
                    </div>
                </div>
                {/* {hasMore &&
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                        <button className="btnmore" onClick={loadMore}>
                            {Strings.all_btn}
                        </button>
                    </div>
                } */}
                {showalert &&
                    <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
                }

                <Footer />
            </div>
        </>
    );
};

export default FinisherScreen;