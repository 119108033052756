import React, { useEffect, useContext, useState } from "react";
import MainNavigator from './screens/MainNavigator'
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import { DataContext } from './utilities/DataContext';
import ReactGA from 'react-ga4';
import Cookies from 'js-cookie';



function App() {


  if (process.env.NODE_ENV === 'production') {
    console.log = function () { };
  }

  const { setFlagsData, flagsData, consentGranted, setConsentGranted } = useContext(DataContext);





  // Initialize analytics only if consent is granted
  useEffect(() => {

    if (flagsData.length == 0) {
      getCountryFlags()
    }

    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').then(registration => {
          console.log('SW registered: ', registration);
        }).catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
      });
    }
  }, []);

  // useEffect(() => {
  //   if (consentGranted) {
  //     initializeAnalytics();
  //   }
  // }, [consentGranted]);


  const getCountryFlags = async () => {
    try {
      const response = await fetch('https://restcountries.com/v3.1/all');
      const countries = await response.json();

      const countryFlagList = countries.map((country) => ({
        name: country.name.common,    // Country name
        code: country.cca2,           // 2-letter country code
        flagUrl: country.flags.svg,   // Flag image URL (SVG format)
      }));
      setFlagsData(countryFlagList)
    } catch (error) {
      console.error('Error fetching country flags:', error);
    }
  };


  return (
    <MainNavigator />
  );
}

export default App;

