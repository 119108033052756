import React, { useState, useEffect } from 'react';
import './FilterDropDown.css'; // Import your CSS file for styling


const FilterDropDown = ({ label, value, onChange, type, short, dropDownData, onSelect, onToggle, isOpen }) => {

    const [singleSelectedItem, setSingleSelectedItem] = useState(null);

    const handleSingleSelectionItemClick = (item) => {
        console.log('item', item)
        setSingleSelectedItem(item.lable);
        onSelect(item);
        // toggleDropdown();
        onToggle();
    };






    return (
        <>
            <div className="filter_dropdown_overview">
                <div className='filter_div_dropdown'>
                    <input placeholder='Select' disabled className='filter_input_dropdown' style={{ width: '80%' }} type={type} value={value} onInput={onChange}></input>
                    <div
                        className="filter_dropdown-icon"
                        style={{ position: 'absolute', top: '50%', right: '8px', transform: 'translateY(-50%)', cursor: 'pointer' }}
                        onClick={onToggle}
                    >
                        <img src={require('../../assets/down_arrow_white.png')} style={{ height: 15, width: 15 }}></img>
                    </div>

                </div>
                {isOpen && (
                    <div className="filter_dropdown-menu-details">
                        {dropDownData.map((item, index) => (
                            <div style={{ backgroundColor: singleSelectedItem === item.lable && 'orange', borderRadius: 5, color: singleSelectedItem === item.lable && 'white', paddingLeft: 10, paddingTop: 5, paddingBottom: 5 }} key={index} className="filter_dropdown-item" onClick={() => { handleSingleSelectionItemClick(item) }}>
                                {item.lable ? item.lable : item}
                                {/* {!singleselection ? multiSelectedItems.includes(item.label) && <IoIosCheckmarkCircle color='green' /> : singleSelectedItem === item && <IoIosCheckmarkCircle color='green' />} */}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default FilterDropDown;