import React, { createContext, useContext, useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
    const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
    const [titleUpdated, setTitleUpdated] = useState(false);
    const [screen, setScreen] = useState('');
    const [screentitleUpdated, setScreenTitleUpdated] = useState(false);
    const [consentGranted, setConsentGranted] = useState(false);
    const location = useLocation();
    const previousPathRef = useRef(location.pathname);
    const previousTitleRef = useRef(document.title);
    const liveGtag = "G-4CG75M28S5";
    const localGtag = "G-NCXB03F9V5";

    // Initialize Analytics
    const initializeAnalytics = () => {
        if (screen === 'businessPage') {
            console.log('screentitleUpdated', screentitleUpdated)
            if (screentitleUpdated === true) {
                if (!isAnalyticsInitialized && consentGranted) {
                    ReactGA.initialize(liveGtag, {
                        gaOptions: { send_page_view: false }
                    });
                    setIsAnalyticsInitialized(true);

                }
            }
        } else {
            if (!isAnalyticsInitialized && consentGranted) {
                ReactGA.initialize(liveGtag, {
                    gaOptions: { send_page_view: false }
                });
                setIsAnalyticsInitialized(true);

            }
        }

    };

    // Send Pageview Event with Debounce to Prevent Duplicates
    const sendPageviewEvent = (title) => {
        if (isAnalyticsInitialized) {
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            console.log('Pageview sent:', title);
            setTitleUpdated(false); // Reset after sending event
        }
    };

    const setAnalyticsIntialization = (screen, istitleupdated) => {
        setScreen(screen)
        setScreenTitleUpdated(istitleupdated)
    }
    // Mark Title as Updated
    const markTitleUpdated = useCallback(() => {
        console.log('title updates called')
        setTitleUpdated(true);
    }, []);

    // Update Consent Status
    const updateConsent = (consent) => {
        setConsentGranted(consent);
    };

    useEffect(() => {
        if (screentitleUpdated === true) {
            initializeAnalytics()
        }
    }, [screentitleUpdated, screen])
    // Initialize Analytics when Consent is Granted
    useEffect(() => {
        if (consentGranted && !isAnalyticsInitialized) {
            initializeAnalytics();
            // Simulate a new page load with a custom event
            ReactGA.send({
                hitType: 'event',
                eventCategory: 'page_view',
                eventAction: 'virtual_pageview',
                page_title: document.title,
                page_location: window.location.href,
            });
        }
    }, [consentGranted, isAnalyticsInitialized]);

    // Observe Title Changes
    useLayoutEffect(() => {
        const titleObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    const newTitle = document.title;
                    if (previousTitleRef.current !== newTitle) {
                        previousTitleRef.current = newTitle;
                        console.log('newTitle', newTitle)
                        markTitleUpdated();
                    }
                }
            });
        });

        const titleElement = document.querySelector('title');
        if (titleElement) {
            titleObserver.observe(titleElement, { childList: true });
        }

        return () => {
            if (titleObserver) {
                titleObserver.disconnect();
            }
        };
    }, []);

    // Send Pageview on Title Change
    useEffect(() => {
        console.log('isAnalyticsInitialized', 'consentGranted', 'titleUpdated', isAnalyticsInitialized, consentGranted, titleUpdated)
        if (isAnalyticsInitialized && consentGranted && titleUpdated) {
            const newTitle = document.title;
            console.log('document.title;', document.title)
            sendPageviewEvent(newTitle);

        }
    }, [isAnalyticsInitialized, consentGranted, titleUpdated]);


    // Track Route Changes
    useLayoutEffect(() => {
        if (isAnalyticsInitialized && consentGranted) {
            const currentTitle = document.title;
            if (previousPathRef.current !== location.pathname && previousTitleRef.current !== currentTitle) {
                previousPathRef.current = location.pathname;
                previousTitleRef.current = currentTitle;

                markTitleUpdated();
                console.log('markTitleUpdated', currentTitle);
            }
        }
    }, [isAnalyticsInitialized, consentGranted, location.pathname]);

    return (
        <AnalyticsContext.Provider value={{ markTitleUpdated, updateConsent, setAnalyticsIntialization }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export const useAnalytics = () => useContext(AnalyticsContext);
