import React, { useContext, useEffect, useRef, useState } from "react";
import '../ErrorPage/ErrorPage.css'
import { DataContext } from "../../utilities/DataContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Strings, URLS, usePageTracking } from "../../utilities/constants";
import AlertComponent from "../../Components/AlertComponent";
import { Row, Col } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import axios from "axios";
import { debounce } from "lodash";
import ReactGA from 'react-ga4';
import { useAnalytics } from "../../utilities/AnalyticsContext";

const AdminLogin = () => {
    const { markTitleUpdated } = useAnalytics()
    const { setPageTitleAvailable, setUserData, setToken, setIsLoggedIn, setUserType, setCompanyInfo, isLoggedIn, isAdmin, setIsAdmin } = useContext(DataContext)
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error1, setError1] = useState('');
    const [error2, setErro2] = useState('');
    const [error, setError] = useState('');
    const [err, setErr] = useState('')
    const [adminerr, setAdminerr] = useState('')
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()
    const message = location.state?.message

    const pageTitle = 'Admin Login'
    const previousTitleRef = useRef();

    useEffect(() => {
        document.title = 'Admin Login'
        // markTitleUpdated(pageTitle)
    }, [])


    const sendAnalyticsEvent = debounce((title) => {
        if (previousTitleRef.current !== title) {
            console.log('Sending pageview event to Google Analytics:', title);
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
            setPageTitleAvailable(true)
            previousTitleRef.current = title;
        }
    }, 500);


    const debouncedTrackPageTitleChange = debounce((title) => {
        if (previousTitleRef.current !== title && window.trackPageTitleChange) {
            window.trackPageTitleChange(title);
        }
        // Update the previous title reference
        previousTitleRef.current = title;
    }, 500);


    // useEffect(() => {
    //     console.log("isLoggedIn value in PrivateRoute:", isLoggedIn);
    //     console.log("screentype", message);
    //     if (isLoggedIn) {
    //         if (isAdmin) {
    //             navigate(`/${message}`)
    //         } else {
    //             setAdminerr('Du hast leider keine Admin-Rechte.')
    //         }

    //     }
    // }, [isLoggedIn, isAdmin]);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const gotoHome = () => {
        navigate('/home')
    }

    const validateForm = () => {
        if (!email.trim()) {
            setError1(Strings.email_err);
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError1(Strings.invalid_email);
            return false;
        }
        if (!password.trim()) {
            setErro2(Strings.password_err);
            return false;
        }
        return true;
    };

    const handleBtClick = () => {
        if (validateForm()) {
            callLoginapi()
        }
    };

    const openNewTab = (url) => {
        setAdminerr('')
        window.open(url, "_blank");
    };


    const callLoginapi = async () => {
        setLoading(true)
        const url = `${URLS.BASE_URL1}${URLS.LOGIN_URL}`;
        console.log('url', url)
        const formdata =
        {
            "email": email,
            "password": password
        }
        console.log('inputdata', formdata)
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json";
        await axios.post(url, formdata)
            .then((response) => {
                setLoading(false)
                if (response?.data.status === 1) {
                    setEmail('');
                    setPassword('')
                    setUserType(response?.data.user.user_type)
                    setIsAdmin(response?.data.user.is_admin)
                    setIsLoggedIn(true)
                    if (response?.data.user.user_type === 'private-user') {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.private_user_profile_information)
                    } else {
                        const data = response?.data.user
                        setUserData(data);
                        setCompanyInfo(response?.data.company_information)
                    }
                    setToken(response?.data?.token)
                    if (response?.data.user.is_admin === true) {
                        navigate(`/${message}`)
                    } else {
                        setAdminerr('Du hast leider keine Admin-Rechte.')
                    }
                } else {
                    console.log('response', response);
                    // alert(response.data.message)
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setLoading(false)
                console.log('error', error);
                setError(error ? error.response.data.message : '')

            });
    }


    return (
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100vh' }}>

            {loading && (
                <div className="loader">
                    <ClipLoader color='orange' loading={loading} size={50} />
                </div>
            )}
            <div style={{ width: '50vh', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: 20, flexDirection: 'column' }}>
                <img
                    src={require("../../assets/logo.webp")}
                    width="200vh"
                    height="150vw"
                    alt='logo'
                    style={{ objectFit: 'contain' }}
                    className="d-inline-block align-top"
                    onClick={() => { openNewTab(URLS.APP_LIVE_URL) }}
                />
                <div className='changepwinput'>
                    <input
                        type='email'
                        placeholder={Strings.email}
                        className='inputcpw'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div style={{ width: '100%', marginBottom: 10 }}>
                    {error1 && (
                        <Row >
                            <Col md={1} style={{ marginTop: -4 }}> <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} /></Col>
                            <Col md={11} style={{ marginLeft: -15 }}><div className="error-message">{error1}</div></Col>
                        </Row>
                    )}
                </div>
                <div className='logininput changepwinput'>
                    <input
                        type={isPasswordVisible ? "text" : 'password'}
                        placeholder={Strings.password}
                        className='inputcpw'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span
                        onClick={() => togglePasswordVisibility()}
                    >
                        {isPasswordVisible ? <img src={require('../../assets/eye_open.png')} style={{ width: 20, height: 20 }} /> : <img src={require('../../assets/eye_close.png')} style={{ width: 20, height: 20 }} />}
                    </span>
                </div>
                <div style={{ width: '100%', marginBottom: 10 }}>
                    {error2 && (
                        <Row >
                            <Col md={1} style={{ marginTop: -4 }}> <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img></Col>
                            <Col md={11} style={{ marginLeft: -15 }}><div className="error-message">{error2}</div></Col>
                        </Row>
                    )}
                </div>
            </div>

            {error && <p style={{ color: 'red', marginTop: -25, marginBottom: 20 }}>{error}</p>}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
            >
                <button className="next-btn" onClick={handleBtClick}>{Strings.login}</button>
            </div>
            {adminerr !== '' &&
                <AlertComponent message={adminerr} onClose={() => { openNewTab(URLS.APP_LIVE_URL) }}></AlertComponent>
            }
        </div>
    )
}

export default AdminLogin;