import React, { useState, useEffect, useContext, useRef } from 'react';
import '../../app.css'
import { debounce } from "lodash";
import './Search.css'
import { useNavigate } from 'react-router-dom';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import { Col, Row } from 'react-bootstrap';
import DropDown from '../../Components/DropDown/DropDown';
import { URLS, usePageTracking } from '../../utilities/constants';
import { DataContext } from '../../utilities/DataContext';
import axios from 'axios';
import CountryFlag from './CountryFlag';
import FilterDropDown from '../../Components/FilterDropDown/FilterDropDown';
import ReactGA from 'react-ga4';
import { useAnalytics } from '../../utilities/AnalyticsContext';


const SearchScreen = () => {
  const { markTitleUpdated } = useAnalytics()
  const navigate = useNavigate();
  const { token, setPageTitleAvailable } = useContext(DataContext);
  const itemsListRef = useRef(null);
  const searchRef = useRef(null);
  const typeData = ['Kastenwagen', 'Teilintegriert', 'Vollintegriert']
  const filterData = [{ lable: 'Select', value: '' }, { lable: 'Preis aufsteigend', value: 'price_ascending' }, { lable: 'Preis absteigend', value: 'price_descending' }, { lable: 'Erste Zulassung aufsteigend', value: 'publish_date_ascending' }, { lable: 'Erste Zulassung absteigend', value: 'publish_date_descending' }, { lable: 'km aufsteigend', value: 'mileage_km_ascending' }, { lable: 'km absteigend', value: 'mileage_km_descending' }]
  const [loading, setLoading] = useState(false);
  const [addsList, setAddsList] = useState([])
  const [addsListCount, setAddsListCount] = useState(0)
  const [manfacturerData, setManufacturerData] = useState([])

  const [openDropdown, setOpenDropdown] = useState(null);

  const [showAdvanceSearch, setShwAdvancedSearch] = useState(false)
  const [conversionBrand, setConversionBrand] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [count, setCount] = useState(0)
  const [manualLoadMore, setManualLoadMore] = useState(false);
  const [formData, setFormData] = useState({
    noofbeds: '',
    pricerangefrom: '',
    pricerangeto: '',
    milagerangefrom: '',
    milagerangeto: '',
    type: [],
    chasisbrand: [],
    conversionbrnd: [],
    filterText: '',
    vehiclename: ''
  })

  const [filtervalue, setFilterValue] = useState('');
  const pageSize = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, settotalPages] = useState(1)


  const pageTitle = 'Suchergebnisse Campervans zu verkaufen';
  const previousTitleRef = useRef();

  useEffect(() => {
    document.title = 'Suchergebnisse Campervans zu verkaufen'
    // markTitleUpdated(pageTitle)
  }, [])

  const sendAnalyticsEvent = debounce((title) => {
    if (previousTitleRef.current !== title) {
      console.log('Sending pageview event to Google Analytics:', title);
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title });
      setPageTitleAvailable(true)
      previousTitleRef.current = title;
    }
  }, 500);


  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
    callBasicDetailsistApi()
  }, [])


  const DEBOUNCE_DELAY = 300;

  // useEffect(() => {
  //   let debounceTimer;

  //   const updatePageSize = () => {
  //     const width = window.innerWidth;
  //     const height = window.innerHeight;
  //     const calculatedPageSize = calculatePageSize(width, height);

  //     // Set the new page size and reset pagination
  //     setPageSize(calculatedPageSize);
  //     setCurrentPage(1);
  //     setNextPage(1);

  //     // Call the API with the new page size
  //     console.log('calculatedPageSize', calculatedPageSize);
  //     callAddsListApi(1, calculatedPageSize);
  //   };

  //   const handleResize = () => {
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => {
  //       updatePageSize();
  //     }, DEBOUNCE_DELAY); // Delay after user stops resizing
  //   };

  //   // Set initial page size
  //   updatePageSize();

  //   // Add the resize event listener with debounce
  //   window.addEventListener('resize', handleResize);

  //   // Cleanup event listener and debounce timer on component unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     clearTimeout(debounceTimer);
  //   };
  // }, []);

  // useEffect(() => {
  //   // document.title = 'Suchergebnisse Campervans zu verkaufen'
  //   const width = window.innerWidth;
  //   const height = window.innerHeight;
  //   const calculatedPageSize = calculatePageSize(width, height);
  //   setPageSize(calculatedPageSize);

  //   callAddsListApi(nextPage, calculatedPageSize)

  // }, []);


  useEffect(() => {
    callAddsListApi(1, pageSize, filtervalue);
    // let debounceTimer;
    // let previousWidth = window.innerWidth;

    // const updatePageSize = () => {
    //   const width = window.innerWidth;
    //   const height = window.innerHeight;
    //   const calculatedPageSize = calculatePageSize(width, height);

    //   // Set the new page size and reset pagination
    //   setPageSize(calculatedPageSize);
    //   setCurrentPage(1);
    //   setNextPage(1);

    //   // Call the API with the new page size
    //   console.log('calculatedPageSize', calculatedPageSize);
    //   callAddsListApi(1, calculatedPageSize);
    // };

    // const handleResize = () => {
    //   const currentWidth = window.innerWidth;

    //   // Only update if the width has changed
    //   if (currentWidth !== previousWidth) {
    //     previousWidth = currentWidth;
    //     clearTimeout(debounceTimer);
    //     debounceTimer = setTimeout(() => {
    //       updatePageSize();
    //     }, DEBOUNCE_DELAY); // Delay after user stops resizing
    //   }
    // };

    // // Set initial page size
    // updatePageSize();

    // // Add the resize event listener with debounce
    // window.addEventListener('resize', handleResize);

    // // Cleanup event listener and debounce timer on component unmount
    // return () => {
    //   window.removeEventListener('resize', handleResize);
    //   clearTimeout(debounceTimer);
    // };
  }, []);


  useEffect(() => {
    if (!manualLoadMore) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [manualLoadMore, hasMore, loading]);

  const calculatePageSize = (width, height) => {

    if (width < 600) {
      // Small devices like mobile
      return Math.floor((height / 100) * 0.5); // Adjust based on height
    } else if (width < 1024) {
      // Tablets
      return Math.floor((height / 100) * 1.2);
    } else {
      // Desktops
      return Math.floor((height / 100) * 1.3);
    }
  };



  const handleInputChange = (label, value) => {
    console.log('label, value', label, value)
    if (label === 'filterText') {
      const filetrvalue = getValueByLabel(value)
      console.log('Updated form value:', filetrvalue);
      setFilterValue(filetrvalue)
      filterClicked(filetrvalue)
    }
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        [label]: value
      };
      console.log('Updated form data:', updatedFormData);
      return updatedFormData;
    });

  }

  function getValueByLabel(label) {
    const item = filterData.find(element => element.lable === label);
    return item ? item.value : null;
  }

  const handleScroll = debounce(() => {
    if (!hasMore || manualLoadMore || loading) return; // Only call API if conditions are met

    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const threshold = document.documentElement.offsetHeight - 100; // Trigger API 100px before reaching the bottom

    if (scrollPosition >= threshold) {
      callAddsListApi(nextPage, pageSize, filtervalue);
    }
  }, 300);


  // Handle page change
  const handlePageChange = (pageNumber) => {
    callAddsListApi(pageNumber, pageSize, filtervalue)
  };

  const handleDropdownToggle = (label) => {
    console.log('label', label)
    console.log('openDropdown', openDropdown)
    setOpenDropdown(openDropdown === label ? null : label);
  };

  const filterClicked = (filterText) => {
    setAddsList([]); // Clear the existing list
    setAddsListCount(0);
    setCount(0); // Reset count
    setManualLoadMore(false); // Re
    setCurrentPage(1)
    setNextPage(1)
    setLoading(true)
    callAddsListApi(1, pageSize, filterText)
    handleScrollToTop()
  }


  const searchClicked = (searchback) => {
    setAddsList([]); // Clear the existing list
    setAddsListCount(0);
    setCount(0); // Reset count
    setManualLoadMore(false); // Re
    setCurrentPage(1)
    setNextPage(1)
    setLoading(true)
    setOpenDropdown(null)
    callAddsListApi(1, pageSize, filtervalue)
    handleScrollToTop()
  }

  // Handle previous and next button clicks
  const handlePrevious = () => {
    console.log('nextpage from previous', nextPage)
    if (nextPage > 1 && currentPage !== totalPages) {
      callAddsListApi(nextPage - 2, pageSize, filtervalue)
    } else {
      callAddsListApi(nextPage - 1, pageSize, filtervalue)
    }
    handleScrollToTop()
  };

  const handleNext = () => {
    setManualLoadMore(false)
    if (nextPage <= totalPages) {
      callAddsListApi(nextPage, pageSize, filtervalue)
    }
    // handleScrollToTop()
  };

  const advancedSearchClicked = () => {
    setShwAdvancedSearch(!showAdvanceSearch)
  }

  const adClicked = (item) => {
    // navigate('/adcreate', { state: { id: item.id } })
    const componentPage = `/ad/${item.id}`;
    navigate(componentPage)
  }

  const handleScrollToTop = () => {
    console.log('itemsListRef.current', itemsListRef.current)
    // // Check if the ref exists and scroll to top
    if (itemsListRef.current) {
      itemsListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formatMilageNumber = (value) => {
    return new Intl.NumberFormat('de-DE').format(value);
  }



  const formatCurrency = (value, currency) => {
    console.log('currency', currency)
    if (value !== '' && currency !== '') {
      let locale = 'de-DE'; // German locale for both EUR and CHF
      let formattedValue = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(value);
      return `${formattedValue} ${currency.toUpperCase()}`;
    } else {
      return ''
    }

  };


  const callBasicDetailsistApi = async () => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.BASIC_DETAILS_LIST}`;
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        console.log('response', response);
        if (response?.data.status === 1) {
          if (response.data.data.ads_manufacturers.length > 0) {
            setManufacturerData(response.data.data.ads_manufacturers)
          }
          if (response.data.data.conversion_brands.length > 0) {
            setConversionBrand(response.data.data.conversion_brands)
          }
        } else {
          console.log('response', response);
          // setShowAlert(true)
          // setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error);
        setLoading(false)
        // if (error.response) {
        //   setShowAlert(true)
        //   setError(error.response.data.message)
        // }
      });
  }

  const buildUrlWithParams = (baseUrl, params) => {
    const query = Object.keys(params)
      .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          return `${key}=${encodeURIComponent(JSON.stringify(value))}`;
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return `${baseUrl}?${query}`;
  };

  const callAddsListApi = async (pageNumber, pageSize, filterText) => {
    setLoading(true)
    const type = formData.type.includes('Alle') ? [] : formData.type
    const chasisBrand = formData.chasisbrand.includes('Alle') ? [] : formData.chasisbrand
    const conversionBrand = formData.conversionbrnd.includes('Alle') ? [] : formData.conversionbrnd

    const url = buildUrlWithParams(`${URLS.BASE_URL}${URLS.SEARCH_ADDS}`, {
      search: formData.vehiclename,
      chasis_brand: chasisBrand,
      conversion_brand: conversionBrand,
      min_mileage: formData.milagerangefrom,
      max_mileage: formData.milagerangeto,
      min_price: formData.pricerangefrom,
      max_price: formData.pricerangeto,
      type: type,
      sort_by: filterText,
      total_no_of_beds: formData.noofbeds,
      page: pageNumber,
      page_size: pageSize
    });

    // const url = `${URLS.BASE_URL}${URLS.SEARCH_ADDS}search=${formData.vehiclename}&chasis_brand=${formData.chasisbrand.join(',')}&conversion_brand=${formData.conversionbrnd.join(',')}&min_mileage=${formData.milagerangefrom}&max_mileage=${formData.milagerangeto}&min_price=${formData.pricerangefrom}&max_price=${formData.pricerangeto}&type=${formData.type.join(',')}`;
    console.log('adurl', url)
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          const data = response.data.data
          console.log('data', data)
          setAddsList((prevAdds) => [...prevAdds, ...data]);
          // setAddsList(data);

          setAddsListCount(response.data.count ? response.data.count : 0)
          setCurrentPage(pageNumber);
          setNextPage((response.data.next && response.data.next !== null) ? response.data.next : pageNumber);
          setHasMore((response.data.next && response.data.next !== null) ? true : false);
          if ((response.data.next) && response.data.next !== null) {
            if (count === 0) {
              setManualLoadMore(true)
            }
            setCount(count + 1)
          }
          if (response.data.count) {
            settotalPages(Math.ceil(response.data.count / pageSize))
          }

          // setAddsList(data)
          // handleScrollToTop()
        } else {
          console.log('response', response);
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        alert(error.response.data.message);
      });
  }

  // Generate pagination buttons with ellipses
  const renderPagination = () => {
    const pages = [];
    // const ellipsis = <span key="ellipsis" style={{ alignSelf: 'center', backgroundColor: 'green' }}>{'...'}</span>;
    const ellipsis = <img src={require('../../assets/dots.png')} key="ellipsis" style={{ alignSelf: 'center', width: 20, height: 20, margin: 5 }} />
    console.log('totalPages', totalPages)
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            style={{ backgroundColor: 'white' }}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page
      pages.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          style={{ backgroundColor: 'white' }}
          className={currentPage === 1 ? 'active' : ''}
        >
          1
        </button>
      );

      // Determine pages to show around the current page
      if (currentPage > 3) {
        pages.push(ellipsis);
      }

      const startPage = Math.max(2, currentPage);
      const endPage = Math.min(totalPages - 1, currentPage);
      console.log('currentPage', currentPage)
      for (let i = startPage; i <= endPage; i++) {
        console.log('startPage', startPage)
        pages.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            style={{ backgroundColor: 'white' }}
            className={currentPage === i ? 'active' : ''}
          >
            {i}
          </button>
        );
      }

      if (currentPage < totalPages - 2) {
        pages.push(ellipsis);
      }

      // Show the last page
      pages.push(
        <button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          style={{ backgroundColor: 'white' }}
          className={currentPage === totalPages ? 'active' : ''}
        >
          {totalPages}
        </button>
      );
    }
    return pages;
  };



  return (
    <>

      <Header />
      <div >
        <div
          className='banner_search'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/search_bg.png"
              })`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingLeft: '10vmin',
            paddingRight: '10vmin',

          }}
        >
          <div className="blur_div">
            <div className='searchdiv' style={{ backgroundColor: 'rgba(0,0,0,0.45)', borderRadius: 5, height: 50, display: 'flex', justifyContent: 'space-between' }}>
              <input ref={searchRef} placeholder='Was suchen Sie?' className='main_search_input' style={{ color: 'white', fontSize: 16 }} value={formData.vehiclename} onChange={e => handleInputChange('vehiclename', e.target.value)} />
              <div style={{ backgroundColor: 'black', paddingLeft: 15, paddingRight: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2 }} onClick={searchClicked}>
                <img style={{ height: 15, width: 15 }} alt="search" src={require('../../assets/search.png')}></img>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', margin: 10, marginBottom: 20, cursor: 'pointer', justifyContent: 'center', }} onClick={advancedSearchClicked}>
              <span style={{ color: 'white', fontSize: 15 }}>Erweiterte Suche</span>
              {showAdvanceSearch ? <img src={require('../../assets/up-arrow-white.png')} style={{ width: 25, height: 25, marginLeft: 20 }} /> : <img src={require('../../assets/down-arrow-white.png')} style={{ width: 25, height: 25, marginLeft: 20 }} />}
            </div>
            {showAdvanceSearch &&
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', paddingBottom: 10 }}>

                <Row style={{ justifyContent: 'center' }}>

                  <Col xxl={6} xl={6} lg={7} md={10} sm={12} style={{ marginTop: 0 }}>
                    <span className='small_header_search' style={{ justifyContent: 'center', display: 'flex' }}>Interior</span>
                    <div style={{ marginTop: 10 }}>
                      <Row>
                        <Col xxl={4} xl={4} lg={5} md={5} sm={5} xs={4}>
                          <span style={{ color: 'white', fontSize: 15 }}>Anzahl der Betten:</span>
                        </Col>
                        <Col xxl={4} xl={4} lg={4} md={4} sm={7} xs={8}>
                          <input min={0} className='search_input' type={'number'} value={formData.noofbeds} onChange={e => handleInputChange('noofbeds', e.target.value)} ></input>
                        </Col>
                      </Row>

                      <Row style={{ marginTop: 15, alignItems: 'center' }}>
                        <Col xxl={4} xl={4} lg={5} md={5} sm={4} xs={5}>
                          <span style={{ color: 'white', fontSize: 15, }}>Preis:</span>
                        </Col>
                        <Col xxl={8} xl={8} lg={7} md={7} sm={8} xs={7}>
                          <Row>
                            <Col xxl={6} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input' min={0} placeholder='Von' type={'number'} value={formData.pricerangefrom} onChange={e => handleInputChange('pricerangefrom', e.target.value)} ></input>
                            </Col>
                            <Col xxl={6} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input' placeholder='Bis' min={0} type={'number'} value={formData.pricerangeto} onChange={e => handleInputChange('pricerangeto', e.target.value)} ></input>
                            </Col>
                          </Row>
                        </Col>


                      </Row>

                      <Row style={{ marginTop: 10, alignItems: 'center' }}>
                        <Col xxl={4} xl={4} lg={5} md={5} sm={4} xs={5}>
                          <span style={{ color: 'white', fontSize: 15, }}>Kilometer:</span>
                        </Col>
                        <Col xxl={8} xl={8} lg={7} md={7} sm={8} xs={7}>
                          <Row>
                            <Col xxl={6} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input' placeholder='Von' min={0} type={'number'} value={formData.milagerangefrom} onChange={e => handleInputChange('milagerangefrom', e.target.value)} ></input>
                            </Col>
                            <Col xxl={6} xl={5} lg={6} md={6} sm={6} xs={6} style={{ marginTop: 5 }}>
                              <input className='search_input' placeholder='Bis' type={'number'} min={0} value={formData.milagerangeto} onChange={e => handleInputChange('milagerangeto', e.target.value)} ></input>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xxl={6} xl={6} lg={5} md={10} sm={12} xs={12}>
                    <span className='small_header_search' style={{ justifyContent: 'center', display: 'flex' }}>Fahrzeugdaten</span>
                    <Row style={{ alignItems: 'center' }}>
                      <Col xxl={4} xl={5} md={5} sm={5} xs={4}>
                        <span style={{ color: 'white', fontSize: 15, }}>Typ:</span>
                      </Col>
                      <Col xxl={4} xl={5} md={6} sm={7} xs={8} style={{ marginTop: 10 }}>
                        <DropDown
                          type={'text'}
                          isOpen={openDropdown === 'Typ'}
                          onToggle={() => handleDropdownToggle('Typ')}
                          label="Typ"
                          value={formData.type}
                          dropDownData={typeData}
                          onChange={e => handleInputChange('type', e.target.value)}
                          onSelect={(item) => handleInputChange('type', item)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: 'center', marginTop: 15 }}>
                      <Col xxl={4} xl={5} md={5} sm={5} xs={4}>
                        <span style={{ color: 'white', fontSize: 15 }}>Basis-Fahrzeug Hersteller:</span></Col>
                      <Col xxl={4} xl={5} md={6} sm={7} xs={8}>
                        <DropDown
                          type={'text'}
                          isOpen={openDropdown === 'Chasis'}
                          onToggle={() => handleDropdownToggle('Chasis')}
                          label="Chasis"
                          value={formData.chasisbrand}
                          dropDownData={manfacturerData}
                          onChange={e => handleInputChange('chasisbrand', e.target.value)}
                          onSelect={(item) => handleInputChange('chasisbrand', item)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ alignItems: 'center', marginTop: 15 }}>
                      <Col xxl={4} xl={5} md={5} sm={5} xs={4}>
                        <span style={{ color: 'white', fontSize: 15 }}>Fahrzeug Ausbauer:</span></Col>
                      <Col xxl={4} xl={5} md={6} sm={7} xs={8} >
                        <DropDown
                          type={'text'}
                          isOpen={openDropdown === 'Conversion'}
                          onToggle={() => handleDropdownToggle('Conversion')}
                          label="Conversion"
                          value={formData.conversionbrnd}
                          dropDownData={conversionBrand}
                          onChange={e => handleInputChange('conversionbrnd', e.target.value)}
                          onSelect={(item) => handleInputChange('conversionbrnd', item)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            }
            <div className="action-container">
              <button className="btnseach" onClick={() => searchClicked('')}>
                Suchen
              </button>
              <div className='filter-dropdown'>
                <FilterDropDown
                  type={'text'}
                  isOpen={openDropdown === 'Filter'}
                  onToggle={() => handleDropdownToggle('Filter')}
                  value={formData.filterText}
                  dropDownData={filterData}
                  onChange={e => handleInputChange('filterText', e.target.value)}
                  onSelect={(item) => handleInputChange('filterText', item.lable)}
                />
              </div>

            </div>
          </div>
        </div>
        <div style={{ position: 'absolute', top: showAdvanceSearch ? '50vh' : '0vh', left: 0, right: 0, height: 10 }} ref={itemsListRef}></div>
        <div className='addcardcontainer'  >
          {/* <div style={{ display: "flex", paddingTop: 10, flexDirection: "column", paddingBottom: 30 }}> */}

          <div className='addsection_cards'>
            {addsList.map((item, index) => (
              <div key={item.id} onClick={() => adClicked(item)} className='adimage' style={{ backgroundImage: item.hero_image_url_2k != null ? `url(${item.hero_image_url_2k})` : null, alignItems: 'flex-end', display: 'flex', justifyContent: 'flex-end' }}>
                {/* <div className='adoverlay' /> */}
                <div className='adddata' style={{ width: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', paddingLeft: 20, width: '100%', paddingTop: 5, paddingBottom: 5 }}>
                    <CountryFlag countryCode={item.country_code} />
                    {/* <h1 className='adtitle' style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1> */}
                    <h1 style={{ fontSize: 17, fontWeight: 400, color: 'white', marginBottom: 0, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.vehicle_name !== '' ? item.vehicle_name : '--'}</h1>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <div>
                        <img src={require('../../assets/seat.png')} style={{ height: 15, width: 15 }} alt='seat' />
                        <span className='addetails'>{item.no_of_seats !== null && item.no_of_seats !== 0 ? item.no_of_seats : '--'}</span>
                      </div>
                      <div>
                        <img src={require('../../assets/bed.png')} style={{ height: 15, width: 15 }} alt='bed' />
                        <span className='addetails'>{item.total_no_of_beds !== null && item.total_no_of_beds !== 0 ? item.total_no_of_beds : '--'}</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>

                        <div md={3}>
                          <span className='addetails'>{item.year_of_manufacture !== null ? item.year_of_manufacture : '--'}</span> </div>
                        <div md={9}><span className='addetails'>{item.mileage_km !== null && item.mileage_km !== 0 ? `${formatMilageNumber(item.mileage_km)} km` : '--'}</span></div>
                      </div>
                      <div>
                        <span className='addetails'>{(item.price !== null && item.currency !== null) ? formatCurrency(item.price, item.currency) : '--'}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {addsList.length > 0 &&
            <div className="pagination_ad">
              {/* <button onClick={handlePrevious} disabled={currentPage === 1} className={currentPage === 1 ? 'disabled' : 'enabled'}>

                <img src={require('../../assets/backcircle.png')} style={{ width: 30, height: 30 }} />
              </button> */}
              {/* {renderPagination()}
              <button onClick={handleNext} disabled={currentPage === totalPages} className={currentPage === totalPages ? 'disabled' : 'enabled'}>
                <img src={require('../../assets/rightcirle.png')} style={{ width: 30, height: 30 }} />
              </button> */}

              {manualLoadMore && (
                <button onClick={handleNext} className="load-more">
                  Mehr Fahrzeuge laden
                </button>
              )}
              {loading && <div className="spinner">
                <img className='settings-icon' src={require('../../assets/settings.png')}></img>
              </div>
              }
            </div>
          }
          {/* </div> */}
        </div>
        {/* {loading && (
          <div className="loader">
            <ClipLoader color='orange' loading={loading} size={50} />
          </div>
        )
        } */}
      </div >



      <Footer />
    </>
  );
};

export default SearchScreen;