import React, { useRef, useEffect, useState, useCallback } from 'react';
import { usePopper } from 'react-popper';
import '../Messaging/Messaging.css';
import { Spinner } from 'react-bootstrap';
import ReactQuill from 'react-quill'
import 'quill/dist/quill.snow.css'

const RightPanel = ({ showRightPannel, intialLoad, pagenumber, messages, newMessage, setNewMessage, handleSendMessage, selectedContactId, loggedInUserId, handleEditMessage, handleDeleteMessage, loadMoreMessages, hasMoreMessages, showSpinner, gotoleft }) => {
    const messagesEndRef = useRef(null);
    const messagesStartRef = useRef(null);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [editMessageId, setEditMessageId] = useState(null);
    const [isRead, setIsRead] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(null);
    const [hoveredMessageId, setHoveredMessageId] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [loadMoreButton, setShowLoadMoreButton] = useState(false)


    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
    });


    useEffect(() => {
        if (hasMoreMessages) {
            // Call a function to load more messages, for example
            // loadMoreMessages();
            setShowLoadMoreButton(true);
        } else {
            setShowLoadMoreButton(false);
        }
        if (selectedContactId && messages) {
            console.log('selectedContactId', selectedContactId)
            console.log('messages', messages)
            const messagesForContact = messages.filter(message =>
                message.sender_user === selectedContactId || message.chat === selectedContactId
            );
            setFilteredMessages(messagesForContact);
        } else {
            setFilteredMessages([]);
        }
    }, [messages, selectedContactId]);

    useEffect(() => {
        console.log('intialLoad', intialLoad)
        if (intialLoad) {
            if (messagesEndRef.current) {
                setTimeout(() => {
                    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
        }
    }, [filteredMessages]);



    const handleLoadMoreClick = () => {
        loadMoreMessages();
        setShowLoadMoreButton(false);
    };




    const handleEditClick = (message) => {
        setEditMessageId(message.id);
        setNewMessage(message.message);
        setIsRead(message.is_read)
        setPopoverVisible(null);
    };

    const handleSendOrEditMessage = () => {
        if (editMessageId && !isRead) {
            handleEditMessage(editMessageId, newMessage);
            setEditMessageId(null);
        } else {
            handleSendMessage();
        }
        setNewMessage('');
    };

    const handleDeleteClick = (messageId) => {
        handleDeleteMessage(messageId);
        setPopoverVisible(null);
    };

    const togglePopover = (messageId) => {
        setPopoverVisible(popoverVisible === messageId ? null : messageId);
    };

    const groupMessagesByDate = () => {
        // Check if filteredMessages is empty
        // if (filteredMessages.length === 0) {
        //     return {};
        // }

        // return filteredMessages.reduce((groups, message) => {
        //     const date = message.created_datetime.split('T')[0];
        //     if (!groups[date]) {
        //         groups[date] = [];
        //     }
        //     groups[date].push(message);
        //     return groups;
        // }, {});
        if (filteredMessages.length === 0) {
            return {};
        }

        return filteredMessages.reduce((groups, message) => {
            const date = message.created_datetime.split('T')[0];
            const time = new Date(message.created_datetime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });


            if (!groups[date]) {
                groups[date] = {};
            }

            if (!groups[date][time]) {
                groups[date][time] = [];
            }

            groups[date][time].push(message);

            return groups;
        }, {});
    };


    const messageGroups = groupMessagesByDate();
    const sortedDates = Object.keys(messageGroups).sort((a, b) => new Date(a) - new Date(b));


    function formatTime(datetime) {
        const dateObj = new Date(datetime);
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    function formatDate(datetime) {
        const dateObj = new Date(datetime);
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    return (
        <>
            {sortedDates.length > 0 ? (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div className="conversation-header">
                        {showRightPannel && <button onClick={gotoleft} style={{ backgroundColor: 'rgba(0,0,0,0)', border: 'none' }} >
                            {/* <IoMdArrowRoundBack size={20} /> */}
                            <img src={require('../../assets/back_icon.png')} style={{ width: 30, height: 30 }}></img>
                        </button>}
                        <span style={{ color: 'gray', flex: 1, display: 'flex', justifyContent: 'center' }}>Start conversation</span>
                    </div>
                    {loadMoreButton && (
                        <button
                            className="load-more-button"
                            onClick={handleLoadMoreClick}
                            style={{ marginTop: '5px', padding: '5px' }}
                        >
                            Mehr laden
                        </button>
                    )}
                    {showSpinner &&
                        <div style={{ marginTop: '10px', justifyContent: 'center', display: 'flex' }}>
                            <Spinner animation="border" />
                        </div>
                    }

                    <div className="messages">
                        <div ref={messagesStartRef} />
                        {sortedDates.map(date => (
                            <div key={date} className="date-group">
                                <div className="date-label">{date === new Date().toISOString().split('T')[0] ? 'Today' : date}</div>
                                {Object.keys(groupMessagesByDate()[date]).sort((a, b) => new Date(`${date} ${a}`) - new Date(`${date} ${b}`)).map(time => (
                                    <div key={time} className="time-group">
                                        <div className={`message-time-${messageGroups[date][time][0].sender_user === loggedInUserId ? 'sent' : 'received'}`}
                                            style={{ textAlign: messageGroups[date][time][0].sender_user === loggedInUserId ? 'right' : 'left' }}>
                                            {time}
                                        </div>
                                        {groupMessagesByDate()[date][time].map(message => (
                                            <div
                                                key={message.id}
                                                className={`message-container ${message.sender_user === loggedInUserId ? 'sent' : 'received'}`}
                                                onMouseEnter={() => setHoveredMessageId(message.id)}
                                                onMouseLeave={() => setHoveredMessageId(null)}
                                            >
                                                <div id={`message-${message.id}`} className={`message ${message.sender_user === loggedInUserId ? 'sent' : 'received'}`} style={{ whiteSpace: 'pre-wrap', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <span dangerouslySetInnerHTML={{ __html: message.message }} className="custom-content" style={{ flexGrow: 1 }} />
                                                    {message.sender_user === loggedInUserId && hoveredMessageId === message.id && (
                                                        <>
                                                            <button style={{ backgroundColor: 'rgba(0,0,0,0)', border: 'none' }} onClick={() => togglePopover(message.id)} ref={setReferenceElement}>

                                                                <img src={require('../../assets/down_arrow.png')} style={{ marginLeft: 5, width: 20, height: 20 }}></img>
                                                            </button>
                                                            {popoverVisible === message.id && (
                                                                <div ref={setPopperElement} style={styles.popper} {...attributes.popper} className="popover-content-message ">
                                                                    <button onClick={() => handleEditClick(message)}>
                                                                        <img src={require('../../assets/edit_gray.png')} style={{ width: 15, height: 15 }} /> Edit
                                                                    </button>
                                                                    <button onClick={() => handleDeleteClick(message.id)}>
                                                                        <img src={require('../../assets/delete_gray.png')} style={{ width: 15, height: 15 }} /> Delete
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>
                    <div className="send-message-box">
                        <div className='send-message-box-innerdiv'>
                            {/* <textarea
                                className='message-input'
                                placeholder="Type a message"
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value)
                                    console.log('mesaage/n', e.target.value)
                                }}
                            /> */}
                            <ReactQuill
                                theme="snow"
                                className='message-input message_quil'
                                value={newMessage}
                                placeholder={'Type a message'}
                                onChange={(content) => {
                                    setNewMessage(content)
                                    console.log('new message', content)
                                }}
                                modules={{
                                    toolbar: false // Disable the toolbar
                                }}
                            />
                            <button className='btn-send' onClick={handleSendOrEditMessage}>
                                <img src={require('../../assets/send-icon.png')} style={{ width: 25, height: 25 }}></img>
                            </button>
                            <div className='linediv'></div>
                            <button className='btn-file'>
                                <img src={require('../../assets/share_icon.png')} style={{ width: 25, height: 25, marginRight: 10 }}></img>
                            </button>
                        </div>
                    </div>
                </div>
            ) : intialLoad ? null : (
                <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <img
                        src={require("../../assets/logo.webp")}
                        width="180vh"
                        height="180vw"
                        alt='logo'
                        style={{ objectFit: 'contain', }}
                    />
                </div>
            )}
        </>
    );
}

export default RightPanel;
