import React, { useState, useRef, useEffect, useContext } from 'react';
import { Row, Col, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import '../../app.css'
import '../Ads/Ads.css';
import TopLabelInput from '../../Components/TpLabelInput/TopLabelInput';
import { formatNumberFromLocale, formatNumberToLocale, newtaburl, Strings, URLS } from '../../utilities/constants';
import { css } from '@emotion/react';
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { modules, formats } from '../../utilities/constants'

import axios from 'axios';
import { DataContext } from '../../utilities/DataContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import AlertComponent from "../../Components/AlertComponent";


const AdOverview = ({ onNextPress, tabchanged, addId, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);


  const { userData, token, addDetails, setAddDetails, tab, setTab } = useContext(DataContext);
  const [showDeletePop, setShowDeletePop] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');
  const [heroImageFile, setHeroImageFile] = useState(null);
  const [heroImgRef, setHeroImgRef] = useState(null);
  const fileInputRef = useRef(null);
  const [error, setError] = useState('')
  const [showalert, setShowAlert] = useState(false)
  const [imgLoadingDone, setImgLoadingDone] = useState(false)
  const [overviewTabOpened, setOverviewTabOpend] = useState(false);
  const [hasimageChange, setHasImageChange] = useState(false)
  const [deleteClicked, setDeleteClicked] = useState(false)
  const [formData, setFormData] = useState({
    vehicleName: "",
    conversionBrand: '',
    price: "",
    currency: "",
    vehicleDescription: "",
    hilight1: "",
    hilight2: "",
    hilight3: "",

  });

  const [formdataError, setFormDataError] = useState({
    vehicleName: '',
    conversionBrand: '',
    price: "",
    currency: "",
    images: "",
  });

  const formDataErrRef = useRef(formdataError);
  const formDataRef = useRef(formData);
  const selectedOptionRef = useRef(selectedOption);
  const heroImageFileRef = useRef(heroImageFile);


  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    setOverviewTabOpend(true)
  }, [overviewTabOpened]);

  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  useEffect(() => {
    formDataErrRef.current = formdataError;
  }, [formdataError]);

  useEffect(() => {
    selectedOptionRef.current = selectedOption;
  }, [selectedOption]);

  useEffect(() => {
    heroImageFileRef.current = heroImageFile;
  }, [heroImageFile]);

  useEffect(() => {
    if (overviewTabOpened !== true) {
      if (addId !== undefined && addId !== '') {
        callAddDetailApi(addId, 'details', 'details')
      } else if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
        callAddDetailApi(addDetails.vehicle_ad_overview_data.id, 'details', 'details')
      }
    }

    return () => {
      console.log('taboverview', tab)
      if (tab !== 'overview' && tab !== '') {
        handleNextClick('tabchange')
        setOverviewTabOpend(false)
      }
    };

  }, [tab]);

  function isBlobURL(url) {
    // Regular expression to match blob URLs
    const blobUrlRegex = /^blob:/i;
    return blobUrlRegex.test(url);
  }


  const handleHeroImageChange = (e) => {
    console.log('e.target.files[0]', e.target.files[0])
    let errors = {};
    if (e.target.files[0] !== undefined) {
      const file = e.target.files[0];
      if (file.size >= 4.5 * 1024 * 1024) { // Check if file size is greater than 5MB
        errors.images = Strings.image_size_error
      } else {
        errors.images = ''
        if (file.name.length > 100) {
          errors.images = Strings.image_filename_error
        } else {
          setHeroImageFile(e.target.files[0]);
          setImgLoadingDone(true)
          setHasImageChange(true)
          setDeleteClicked(false)
        }
      }
      setFormDataError(errors);
    }
  };

  const handleInputChange = (label, value) => {
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        [label]: value
      };
      console.log('Updated form data:', updatedFormData);
      return updatedFormData
    })
  }

  const openNewTab = (adid, addname) => {
    // Check if running in development or production
    console.log('addDetails', addDetails)
    const formattedAddName = formatAddName(addname);
    // const baseUrl =
    //   process.env.NODE_ENV === "development"
    //     ? URLS.APP_LOCAL_URL
    //     : URLS.APP_STAGIN_URL;
    const baseUrl = newtaburl
    const componentPage = `ad/${adid}`;
    const url = baseUrl + componentPage;
    window.open(url, "_blank");
  };


  const handleNextClick = (btnAction) => {
    const errors = validateFormData();
    console.log('Object.keys(errors', errors)
    setFormDataError(errors);
    if (Object.keys(errors).length === 0) {
      if (heroImageFile !== null) {
        if (imgLoadingDone) {
          if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
            callAddsOverviewUpdateApi(btnAction)
          } else {
            console.log('add creating')
            callAddsCreateApi(btnAction)
          }
        }
      } else {
        if (!isEmptyObject(addDetails) && addDetails.vehicle_ad_overview_data.id) {
          callAddsOverviewUpdateApi(btnAction)
        } else {
          console.log('add creating')
          callAddsCreateApi(btnAction)
        }
      }
    }
  }

  const handleOptionSelect = (option) => {
    console.log('option', option)
    setSelectedOption(option)
  };

  const handleProcedureContentChange = (content) => {
    console.log("content---->", content);
    setFormData(prevFormData => {
      const updatedFormData = {
        ...prevFormData,
        vehicleDescription: content
      };
      console.log('Updated form data:', updatedFormData);
      return updatedFormData;
    });
  };

  const handleHeroImageLoaded = async (e) => {

    if (heroImgRef === null) {
      let img = e.target.src;
      console.log("heroImage", img);
      try {
        const response = await fetch(img);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const imgUrl = URL.createObjectURL(blob);
        img = imgUrl;

        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const imgElement = new Image();
          imgElement.src = reader.result;

          imgElement.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set maximum width and height (adjust as needed)
            const maxWidth = 1920; // You can adjust this value
            const maxHeight = 1080; // You can adjust this value

            // Calculate new dimensions to fit within maxWidth and maxHeight while preserving aspect ratio
            let newWidth = imgElement.width;
            let newHeight = imgElement.height;

            if (newWidth > maxWidth || newHeight > maxHeight) {
              const aspectRatio = newWidth / newHeight;

              if (newWidth > maxWidth) {
                newWidth = maxWidth;
                newHeight = newWidth / aspectRatio;
              }

              if (newHeight > maxHeight) {
                newHeight = maxHeight;
                newWidth = newHeight * aspectRatio;
              }
            }

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with new dimensions
            ctx.drawImage(imgElement, 0, 0, newWidth, newHeight);

            canvas.toBlob((blob) => {
              const file = new File([blob], 'image.jpg', {
                type: 'image/jpeg', // Change the type according to your original image
                lastModified: Date.now()
              });

              setHeroImageFile(file);
              setImgLoadingDone(true)
              console.log("Converted file size:", file.size);
              console.log("Converted file ", file);
              // Now you have the file with the size below 4.5 MB
              // You can use this file for your further processing
            }, 'image/jpeg', 0.9); // Adjust JPEG quality as needed (0.7 is just an example)
          };
        };
        setHeroImgRef(e.target);
      } catch (error) {
        console.error("error", error);
      } finally {
      }
    }
  };




  const formatAddName = (name) => {
    return name
      .toLowerCase()                          // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-')            // Replace non-alphanumeric characters with a hyphen
      .replace(/^-+|-+$/g, '');               // Remove leading or trailing hyphens
  };

  const handleMouseEnter = () => {
    console.log('showing----')
    setShowDeletePop(true);
  };

  const handleMouseLeave = () => {
    setShowDeletePop(false);
  };

  const handleDeleteClick = () => {
    setShowDeletePop(false);
    setHeroImageFile(null)
    setDeleteClicked(true)
  };






  const validateFormData = () => {
    const errors = {};

    if (heroImageFile === undefined || heroImageFile === "" || heroImageFile === null) {
      errors.images = "Bitte füllen Sie dieses Pflichtfeld aus.";
    }

    if (formDataRef.current.vehicleName === undefined || formDataRef.current.vehicleName === "" || formDataRef.current.vehicleName === null) {
      errors.vehicleName = "Bitte füllen Sie dieses Pflichtfeld aus.";
    }
    if (formDataRef.current.conversionBrand === undefined || formDataRef.current.conversionBrand === "") {
      errors.conversionBrand = "Bitte füllen Sie dieses Pflichtfeld aus.";
    }
    if (formDataRef.current.price === undefined || formDataRef.current.price === "") {
      errors.price = "Bitte füllen Sie dieses Pflichtfeld aus.";
    }

    if (!selectedOptionRef.current) {
      console.log('selectedOptionRef.current ', selectedOptionRef.current)
      errors.currency = "Bitte füllen Sie dieses Pflichtfeld aus.";
    }

    return errors;
  }


  const callAddsCreateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHILCE_ADD_CREATE}`;
    const form = new FormData();
    let status = ''
    status = (heroImageFileRef.current !== null && formDataRef.current.vehicleName !== '' && formDataRef.current.price !== "" && selectedOptionRef.current !== "") ? 'completed' : 'incomplete'

    form.append('user', userData ? userData.id : '')
    form.append('status', status)
    form.append('hero_image', heroImageFileRef.current === null ? '' : heroImageFileRef.current)
    form.append('vehicle_name', formDataRef.current.vehicleName)
    form.append('vehicle_description', formDataRef.current.vehicleDescription)
    form.append('price', (formDataRef.current.price !== "" || formDataRef.current.price !== null) ? formatNumberFromLocale(formDataRef.current.price) : '')
    form.append('currency', selectedOptionRef.current)
    form.append('highlight_1', formDataRef.current.hilight1)
    form.append('highlight_2', formDataRef.current.hilight2)
    form.append('highlight_3', formDataRef.current.hilight3)
    form.append('vehicle_detail', '')
    form.append('conversion_brand', formDataRef.current.conversionBrand)

    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.post(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        console.log('response add create', response);
        setLoading(false)
        if (response?.data.status === 1) {
          setHasImageChange(false)
          callAddDetailApi(response.data.vehicle_ad_overview_data.id, 'create', btnAction)
          // callAddDetailApi(response?.data.vehicle_ad_overview_data.id, 'create', btnAction)
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }



  const callAddDetailApi = async (addId, isFrom, btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.FULL_ADD_DETAILS}${addId}`;
    await axios.get(url, { headers: { 'Authorization': token } })
      .then((response) => {
        setLoading(false)
        if (response?.data.status === 1) {
          setAddDetails(response.data)
          const data = response.data.vehicle_ad_overview_data
          console.log('add details', data);
          setFormData({
            vehicleName: data !== null && data.vehicle_name ? data.vehicle_name : "",
            vehicleDescription: data !== null && data.vehicle_description ? data.vehicle_description : "",
            hilight1: data !== null && data.highlight_1 ? data.highlight_1 : "",
            hilight2: data !== null && data.highlight_2 ? data.highlight_2 : "",
            hilight3: data !== null && data.highlight_3 ? data.highlight_3 : "",
            currency: data !== null ? data.currency : "",
            price: data !== null ? data.price != null ? formatNumberToLocale(data.price) : "" : "",
            conversionBrand: data !== null && data.conversion_brand ? data.conversion_brand : "",
          });
          const heroimg = data !== null ? data.hero_image : null
          setHeroImageFile(heroimg)
          setSelectedOption(data !== null ? data.currency !== null ? data.currency : "" : '')
          if (isFrom === 'create' || isFrom === 'update') {
            if (btnAction == 'next') {
              onNextPress()

            } else if (btnAction == 'preview') {
              openNewTab(response.data.vehicle_ad_overview_data.id, response.data.vehicle_ad_overview_data.vehicle_name)
            } else if (btnAction == 'tabchange') {
              onClick()
            }
          }
        } else {
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        console.log('error', error);
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }


  const callAddsOverviewUpdateApi = async (btnAction) => {
    setLoading(true)
    const url = `${URLS.BASE_URL}${URLS.VEHILCE_ADD_OVERVIEW_UPDATE}${addDetails.vehicle_ad_overview_data.id}/`;
    const form = new FormData();

    let status = ''
    status = (heroImageFileRef.current !== null && formDataRef.current.vehicleName !== '' && formDataRef.current.price !== "" && selectedOptionRef.current !== null) ? 'completed' : 'incomplete'

    form.append('user', userData ? userData.id : '')
    if (hasimageChange) {
      form.append('hero_image', heroImageFileRef.current)
    } else if (deleteClicked) {
      form.append('hero_image', null)
    }
    form.append('status', status)
    form.append('vehicle_name', formDataRef.current.vehicleName)
    form.append('vehicle_description', formDataRef.current.vehicleDescription)
    form.append('price', (formDataRef.current.price !== "" || formDataRef.current.price !== null) ? formatNumberFromLocale(formDataRef.current.price) : '')
    form.append('currency', selectedOptionRef.current)
    form.append('highlight_1', formDataRef.current.hilight1)
    form.append('highlight_2', formDataRef.current.hilight2)
    form.append('highlight_3', formDataRef.current.hilight3)
    form.append('conversion_brand', formDataRef.current.conversionBrand)
    form.append('vehicle_detail', addDetails.vehicle_ad_overview_data.vehicle_detail !== '' || addDetails.vehicle_ad_overview_data.vehicle_detail !== undefined ? addDetails.vehicle_ad_overview_data.vehicle_detail : '')
    const formDataObject = {};
    form.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log('formDataObject', formDataObject);
    axios.defaults.headers.common["Accept"] = "application/json";
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    await axios.patch(url, form, { headers: { 'Authorization': token } })
      .then((response) => {
        console.log('response add update', response);
        if (response?.data.status === 1) {
          setHasImageChange(false)
          setDeleteClicked(false)
          if (btnAction == 'next') {
            setLoading(false)
            onNextPress()
          } else if (btnAction == 'preview') {
            setLoading(false)
            openNewTab(addDetails.vehicle_ad_overview_data.id, formDataRef.current.vehicleName)
          } else if (btnAction == 'tabchange') {
            setLoading(false)
            onClick()
          }
          // callAddDetailApi(response?.data.vehicle_ad_overview_data.id, 'update', btnAction)
        } else {
          setLoading(false)
          console.log('response', response);
          setShowAlert(true)
          setError(response.data.message)
        }
      })
      .catch(function (error) {
        console.log('error', error)
        setLoading(false)
        if (error.response) {
          setShowAlert(true)
          setError(error.response.data.message)
        }
      });
  }



  return (
    <>
      <div>
        {loading && (
          <div className="loader">
            <ClipLoader
              color='orange'
              loading={loading}
              size={50}
            />
          </div>

        )}
        <Row>
          <Col md={8}>
            <div style={{ marginBottom: 15 }}>
              <h6>Hero Image</h6>
              <div className="dashed-view">
                {heroImageFile !== null ?
                  <div htmlFor="fileInput" className="upload-label" onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
                    <OverlayTrigger
                      show={showDeletePop}
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay=
                      {
                        <Popover placement="top">
                          <div className='popover-content'>
                            <Button className="m-1" onClick={() => handleDeleteClick()}>
                              <img src={require('../../assets/delete.png')} style={{ width: 15, height: 15 }}></img>
                            </Button>
                          </div>
                        </Popover>
                      }
                    >
                      <div style={{ height: '100%' }}>
                        {heroImageFile !== null && (heroImageFile instanceof File || heroImageFile instanceof Blob) ? (
                          <img src={URL.createObjectURL(heroImageFile)} alt="" className="hero-image" />
                        ) :
                          (heroImageFile !== null && isBlobURL(heroImageFile)) ? (
                            <img src={heroImageFile} alt="" className="hero-image" />
                          ) :
                            heroImageFile !== null ? (
                              <img src={heroImageFile} ref={heroImgRef} onLoad={handleHeroImageLoaded} alt="" className="hero-image" />
                            ) : null}
                        <input style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, cursor: 'pointer' }} ref={fileInputRef} type="file" accept="image/*" onChange={handleHeroImageChange} />
                      </div>
                    </OverlayTrigger>
                  </div> :
                  <div htmlFor="fileInput" className="upload-label">
                    {!heroImageFile ? (
                      <div className='upload-placeholder'>
                        <img alt="" style={{ width: 15, height: 15 }} src={require('../../assets/placeholder.png')}></img>
                      </div>
                    ) : null}
                    <input style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, cursor: 'pointer' }} ref={fileInputRef} type="file" accept="image/*" onChange={handleHeroImageChange} />
                  </div>
                }

              </div>

              {(formdataError.images) ? (
                <div style={{ marginBottom: 20, display: 'flex', flexDirection: 'row' }}>
                  <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} />
                  <div className="error-message">{formdataError.images}</div>
                </div>
              ) : null}
            </div>
            <Row style={{ marginTop: 20 }}>
              <Col md={12} xl={6} xs={10} lg={10}>
                <TopLabelInput type={'text'} label={Strings.vehicle_name} value={formData.vehicleName} onChange={e => handleInputChange('vehicleName', e.target.value)} />
                {formDataErrRef.current.vehicleName && (
                  <Row style={{ marginBottom: 20 }}>
                    <Col md={1} style={{ marginTop: -4 }}> <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }}></img></Col>
                    <Col md={11} style={{ marginLeft: -25 }}><div className="error-message">{formDataErrRef.current.vehicleName}</div></Col>
                  </Row>
                )}
                <Row>
                  <Col md={7} xl={6} lg={8} xs={7}>
                    <TopLabelInput minimumNumber={1} inputtype={'integer'} type={'text'} label={Strings.price} value={formData.price} onChange={e => handleInputChange('price', e.target.value)} />
                  </Col>

                  <Col md={5} xl={6} lg={4} xs={5}>
                    <span className='curenyheader' style={{ marginTop: -20 }}>{Strings.currency}</span>
                    <div className="option-container">
                      <div
                        className={`currencyoption ${selectedOption == 'eur' ? 'selected' : ''}`}
                        onClick={() => handleOptionSelect('eur')}
                      >
                        EUR
                      </div>
                      <div
                        className={`currencyoption ${selectedOption == 'chf' ? 'selected' : ''}`}
                        onClick={() => handleOptionSelect('chf')}
                      >
                        CHF
                      </div>
                    </div>
                  </Col>
                </Row>
                {(formdataError.price || formdataError.currency) ? (
                  <Row style={{ marginBottom: 20 }}>
                    <Col md={1} style={{ marginTop: -4 }}> <img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} /></Col>
                    <Col md={11} style={{ marginLeft: -25 }}><div className="error-message">{formdataError.price ? formdataError.price : formdataError.currency}</div></Col>
                  </Row>
                ) : null}
                <TopLabelInput type={'text'} label={Strings.conversion_brand} value={formData.conversionBrand} onChange={e => handleInputChange('conversionBrand', e.target.value)} />
                {formDataErrRef.current.conversionBrand && (
                  <Row style={{ marginBottom: 20 }}>
                    <Col md={1} style={{ marginTop: -4 }}><img src={require('../../assets/alert.png')} style={{ height: 20, width: 20 }} /></Col>
                    <Col md={11} style={{ marginLeft: -25 }}><div className="error-message">{formDataErrRef.current.conversionBrand}</div></Col>
                  </Row>
                )}
              </Col>
              <Col md={12} xl={6} lg={10} xs={10}>
                <div className='heilightdiv'>
                  <span className='hilight_floatlabel'>{Strings.heilights}</span>
                  <div style={{ marginTop: -10 }}>
                    <input className='inputheilight' placeholder='Highlight 1' value={formData.hilight1} onInput={e => handleInputChange('hilight1', e.target.value)}></input>
                    <div className='inputborder' />
                  </div>
                  <div>
                    <input className='inputheilight' placeholder='Highlight 2' value={formData.hilight2} onInput={e => handleInputChange('hilight2', e.target.value)}></input>
                    <div className='inputborder' />
                  </div>
                  <div>
                    <input className='inputheilight' placeholder='Highlight 3' value={formData.hilight3} onInput={e => handleInputChange('hilight3', e.target.value)}></input>
                    <div className='inputborder' />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={10} xl={12} xs={10}>
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  className='quill_adds'
                  formats={formats}
                  value={formData.vehicleDescription}
                  placeholder="Beschreibung ...."
                  onChange={handleProcedureContentChange}
                />
              </Col>
            </Row>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, marginBottom: 20 }}>
              <button className="next-btn" style={{ minWidth: 120, marginRight: 10 }} onClick={() => { handleNextClick('next') }}>Weiter</button>
              <button className="add_preview_btn" style={{ minWidth: 120 }} onClick={() => { handleNextClick('preview') }} >Preview<img src={require('../../assets/desktop.png')} style={{ width: 15, height: 15, marginLeft: 5 }} /></button>
            </div>
          </Col>
          <Col md={4}>

          </Col>
          {showalert &&
            <AlertComponent message={error} onClose={() => { setShowAlert(false) }}></AlertComponent>
          }
        </Row>

      </div>
    </>
  )
}

export default AdOverview